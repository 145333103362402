import React, { Component } from 'react';
import { Range, getTrackBackground } from "react-range";


class RangeField extends Component {

  constructor(props) {
    super(props);
    
  }
  
  state = {
    values: [50]
  };

  render() {

    const STEP = 0.1;
    const MIN = 0;
    const MAX = 100;
    let barHeight = this.props.barHeight;
    let iconHeight = this.props.iconHeight;
    let iconWidth = this.props.iconWidth;

    return (
      <div>
        <Range
          values={this.state.values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => this.setState({ values })}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: barHeight,
                  width: "100%",
                  borderRadius: "30px",
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: ["#548BF4", "#ccc"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: iconHeight,
                width: iconWidth,
                borderRadius: "50px",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA"
              }}
            >
              {/* <div
                style={{
                  height: "16px",
                  width: "16px",
                  backgroundColor: isDragged ? "#548BF4" : "#CCC"
                }}
              /> */}
            </div>
          )}
        />
      </div>
    );
  }
}

export default RangeField;