import { HashRouter as Router } from "react-router-dom";
import {
  Switch, Route } from "react-router-dom";
import './App.css';
import Main from "./components/carinsurance/english/Main";
import PolicyList from "./components/common/policy_listing/english/PolicyList";
import ScrollToTop from "./components/common/ScrollToTop";
import Complaint from "./components/Complaints/english/Complaint";
import ClaimAssistance from "./components/home/english/ClaimAssistance";
import Home from './components/home/english/Home';
import HealthMain from "./components/healthinsurance/english/Main";
import ContactUs from "./components/layout/english/ContactUs";
import GeneralInsurance from "./components/otherinsurance/english/GeneralInsurance";
import InsuranceForms from "./components/otherinsurance/english/InsuranceForms";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/car-insurance" component={Main} />
          <Route path="/health-insurance" component={HealthMain} />
          {/* <Route path="/policy-list" component={PolicyList} /> */}
          <Route path="/claim-assistance" component={ClaimAssistance} />
          <Route path="/insurance/form/:cname" component={InsuranceForms} />
          <Route path="/generalinsurance" component={GeneralInsurance} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/complaints" component={Complaint} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
