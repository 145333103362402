import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import Swal from 'sweetalert2';
import { PUBLIC_URL } from '../../../CommonString';
import CustomerDetails from '../../carinsurance/english/CustomerDetails';
import Footer from '../../layout/english/Footer';
import SpinnerComponent from '../../common/SpinnerComponent';
import Header from '../../layout/english/Header';
import OTPInput, { ResendOTP } from "otp-input-react";
import { text } from 'dom-helpers';
let customValue = [];

const InsuranceForms = (props) => {

    let cname = props.match.params.cname;
    let categoryName = ((new URLSearchParams(props.location.search).get('category')) != '') ? (new URLSearchParams(props.location.search).get('category')) : '';
    let categoryId = ((new URLSearchParams(props.location.search).get('id')) != '') ? (new URLSearchParams(props.location.search).get('id')) : '';
    const [firstLoad, setFirstLoad] = useState(true);
    const [form, setForm] = useState([]);
    const [page, setPage] = useState(1);
    const [details, setDetails] = useState({
        name: '',
        email: '',
        mobile: '',
        day: '',
        month: '',
        year: '',
        dob: '',
        gender: '',
        nationality: '',
        nationalityId: '',
        smoker: '',
    });
    const [ipAddress, setIpAddress] = useState('');
    const [newImage, setNewImage] = useState('');
    const history = useHistory();
    const [backEnable, setBackEnable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [OTPError, setOTPError] = useState("");
    const [verficationId, setverficationId] = useState([]);
    const [verifiedOtp, setVerifiedOtp] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {

        if (firstLoad == true) {

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            setLoading(true);
            axios({
                url: `${PUBLIC_URL}/category/form`,
                method: 'POST',
                data: {
                    cname: cname,
                }
            }).then(response => {
                setLoading(false);
                if (response.data.status === 'success') {
                    setForm(response.data.form);

                    if (response.data.form.length === 0) {
                        setPage(2);
                        setBackEnable(false);
                    }
                }

            }).catch((error) => {
                setLoading(false);
            });
        }

        setFirstLoad(false);
    });




    const pageUpdate = () => {

        if (page === 2) {

            if (details.name !== '' && details.email !== '' && details.mobile !== '') {

                let otpData = new FormData();
                otpData.append('mobile', details.mobile);

                axios({
                url: `${PUBLIC_URL}/generate/otp`,
                method: 'POST',
                data: otpData,

                }).catch((error) => {});
                
                setPage(3);

            }
            else {}
        }
        else if (page === 3) {

            let verifyData = new FormData();
            let verified_otp = '';

            verifyData.append('mobile', details.mobile);
            verifyData.append('otp', OTP);

            setLoading(true);
            setDisableButton(true);

            axios({
                url: `${PUBLIC_URL}/verify/otp`,
                method: 'POST',
                data: verifyData,

            }).then(response => {

                if(response.data.status === 'success'){

                    setVerifiedOtp(response.data.otp);
                    verified_otp = response.data.otp;

                    if (OTP == verified_otp) {

                        setLoading(true);

                        setOTPError('');
                        
                        axios({
                            url: `${PUBLIC_URL}/enquiry/store`,
                            method: 'POST',
                            data: {
                                category: categoryId,
                                ip_address: ipAddress,
                                name: details.name,
                                email: details.email,
                                phone: details.mobile,
                                gender: details.gender ? details.gender : null,
                                nationality: details.nationalityId ? details.nationalityId : null,
                                // date_of_berth: details.dob ? details.dob : null,
                                date_of_berth: "",
                                smoker: details.smoker ? details.smoker : null,
                                field_value: customValue,
                            },
                        }).then(response => {
                            setLoading(false);
                            if (response.data.status === 'success') {
        
                                Swal.fire({
                                    title: '<strong>Thank You</strong>',
                                    icon: 'success',
                                    html:
                                        'One of our Member is working on your custom quote and will be in touch shortly.',
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    focusConfirm: false,
                                    confirmButtonText:
                                        '<i class="fa fa-thumbs-up"></i> Go to home page!',
                                    confirmButtonAriaLabel: 'Thumbs up, great!',
        
                                }).then((result) => {
                                    history.push('/');
                                });
        
                            }
                            else {
                                Swal.fire({
                                    title: 'error!',
                                    text: response.data.message,
                                    icon: 'error',
                                });
                            }
        
                        }).catch((error) => {
                            setLoading(false);
                            Swal.fire({
                                title: 'Error!',
                                text: 'Something went wrong',
                                icon: 'error',
                            });
        
                        });
        
                    }
                }
                else{
                    setLoading(false);
                    setDisableButton(false);
                    setOTPError(response.data.message);
                }

            }).catch((error) => {
                setLoading(false);
            });
            
        }
        else {

            if(form.length > 0){
                
                form.map((forms) => {
                    if(forms.field.required == 1){
                        
                        if (customValue.some(data => data.field_id == forms.field.id)){
                           
                           setPage(page + 1);
                        }
                        else{
                            
                            setForm(
                                form.filter(filterForm => {
                                    if(filterForm.field.id == forms.field.id){

                                        let formError = forms.field.name + ' field is required';

                                        filterForm.error = formError;
                                    }
                                    else{
                                        filterForm.error = '';
                                    }

                                    return filterForm;

                                })
                            )
                        }
                    }
                });
            }
            else{
                setPage(page + 1);
            }
            
        }
    }

    const pageDown = () => {
        setPage(page - 1);
    }

    const customerDetails = (customer) => {

        setDetails({
            name: customer.name,
            email: customer.email,
            mobile: customer.mobile,
            day: customer.day,
            month: customer.month,
            year: customer.year,
            dob: customer.dob,
            gender: customer.gender,
            nationality: customer.nationality,
            nationalityId: customer.nationalityId,
            smoker: customer.smoker,
        });
    }

    let customerDetailsValues = {
        name: details.name,
        email: details.email,
        mobile: details.mobile,
        day: details.day,
        month: details.month,
        year: details.year,
        dob: details.dob,
        gender: details.gender,
        nationality: details.nationality,
        nationalityId: details.nationalityId,
        smoker: details.smoker,
    }

    const handleChange = (e) => {

        if (customValue.some(data => data.field_id == e.target.name)) {
            const elementsIndex = customValue.findIndex(element => element.field_id == e.target.name)
            customValue[elementsIndex].value = e.target.value;
        }
        else {
            customValue.push({ 'field_id': e.target.name, 'value': e.target.value });
        }

        if(form.length > 0){
                
            form.map((forms) => {
                if(forms.field.required == 1){
                    
                    if (customValue.some(data => data.field_id == forms.field.id)){
                       
                        setForm(
                            form.filter(filterForm => {
                                if(filterForm.field.id == forms.field.id){

                                    let formError = '';

                                    filterForm.error = formError;
                                }
                                else{
                                    filterForm.error = '';
                                }

                                return filterForm;

                            })
                        )
                    }
                    else{
                        
                        setForm(
                            form.filter(filterForm => {
                                if(filterForm.field.id == forms.field.id){

                                    let formError = forms.field.name + ' field is required';

                                    filterForm.error = formError;
                                }
                                else{
                                    filterForm.error = '';
                                }

                                return filterForm;

                            })
                        )
                    }
                }
            });
        }
        
    }

    const fileChange = (e) => {

        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = (event) => {

            setNewImage(event.target.result);

            if (customValue.some(data => data.field_id == e.target.name)) {
                const elementsIndex = customValue.findIndex(element => element.field_id == e.target.name)
                customValue[elementsIndex].value = event.target.result;
            }
            else {
                customValue.push({ 'field_id': e.target.name, 'value': event.target.result });
            }
        };
    }

    const mystyle = {
        width: "50px",
        height: "50px",
        backgroundColor: "lighten(#0f0f1a, 5%)",
        // border: "none",
        lineHeight: "50px",
        textAlign: "center",
        fontSize: "24px",
        display: "initial",
        fontWeight: "200",
        color: "#172f3b",
        margin: "0 2px",
    };

    const sendOtp = () => {

        let otpData = new FormData();
        otpData.append('mobile', details.mobile);

        setLoading(true);
    
        axios({
          url: `${PUBLIC_URL}/generate/otp`,
          method: 'POST',
          data: otpData,
    
        }).then(response => {

            setLoading(false);

        }).catch((error) => {});
        
      }

    const renderButton = (buttonProps) => {
        return (
            <button {...buttonProps}>
                {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : <a onClick={sendOtp}>Resend</a>}
            </button>
        );
    }

    const renderTime = () => React.Fragment

    
    return (
        <>
            <Header />
            <SpinnerComponent open={loading} />

            <section className="other-insurance-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="steps-form">
                                <div className="form-wizard">
                                    <div className="steps">
                                    </div>
                                    <div className="myContainer" style={{ minWidth: '30vw', minHeight: '60vh' }}>
                                        {page === 1 ?
                                            <div className="form-container animated active flipInX">
                                                <h2 className="text-center form-title">{categoryName}</h2>



                                                {form && form.map((form, index) => {
                                                    if (form.field.type === 'text') {
                                                        return (
                                                            <div key={index} className="form-group">
                                                                <input type="text" className="form-control" onChange={handleChange} name={form.field.id} placeholder={form.field.name} />
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'select') {
                                                        return (
                                                            <>
                                                                <select key={index} name={form.field.id} onChange={handleChange} className="custom-select mb-3">
                                                                    <option value="" disabled selected>{form.field.name}</option>
                                                                    {form.field.field_option.map((option, index) => {
                                                                        return <option key={index} value={option.value}>{option.value}</option>
                                                                    })}
                                                                </select>
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </>
                                                        );
                                                    }
                                                    else if (form.field.type === 'textarea') {
                                                        return (
                                                            <div key={index} className="form-group">
                                                                <textarea className="form-control" onChange={handleChange} name={form.field.id} placeholder={form.field.name}></textarea>
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'file') {
                                                        return (
                                                            <div className="form-group" key={index}>
                                                                <div className="custom-file">
                                                                    <input type="file" name={form.field.id} onChange={fileChange} className="custom-file-input form-control" />
                                                                    <label className="custom-file-label" for="customFile" style={{ marginLeft: '0px', marginRight: '0px' }}>Choose {form.field.name} file</label>
                                                                </div>
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'url') {
                                                        return (
                                                            <div key={index} className="form-group">
                                                                <input type="text" className="form-control" onChange={handleChange} name={form.field.id} placeholder={form.field.name} />
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'number') {
                                                        return (
                                                            <div key={index} className="form-group">
                                                                <input type="number" className="form-control" onChange={handleChange} name={form.field.id} placeholder={form.field.name} />
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'date') {
                                                        return (
                                                            <div key={index} className="form-group">
                                                                <input type="date" className="form-control" onChange={handleChange} name={form.field.id} placeholder={form.field.name} />
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'checkbox') {
                                                        return (
                                                            <div key={index} className="form-group">
                                                                <input type="checkbox" onChange={handleChange} id={form.field.name} name={form.field.id} value={form.field.value} />
                                                                <label for={form.field.name}>{form.field.name}</label>
                                                                {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                            </div>
                                                        );
                                                    }
                                                    else if (form.field.type === 'radio') {
                                                        return (
                                                            <div key={index}>
                                                                <div>{form.field.name}</div>
                                                                <div className="row">
                                                                    {form.field.field_option.map((option, index) => {
                                                                        return (
                                                                            <div key={index} className="col-md-6">
                                                                                <div className="form-group row container">
                                                                                    <input type="radio" onChange={handleChange} id={option.value} name={form.field.id} value={option.value} />
                                                                                    <label className="mt-1" for={option.value}>{option.value}</label>
                                                                                    {form.error ? <div className="text-danger">{form.error}</div>: ''}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                                <div className="form-group text-center ">
                                                    {/* <input type="button" value="BACK" className="btn btn-default back mr-2" />  */}
                                                    <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />
                                                </div>
                                            </div>
                                            : page === 3 ?
                                                <div className="form-container animated active flipInX">
                                                    <h2 className="text-center form-title">{categoryName}</h2>
                                                    <OTPInput
                                                        value={OTP}
                                                        onChange={setOTP}
                                                        autoFocus OTPLength={6}
                                                        otpType="number"
                                                        disabled={false}
                                                        inputStyles={mystyle}
                                                    />
                                                    {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                                                    <ResendOTP renderButton={renderButton} renderTime={renderTime} />
                                                    {OTPError ? <div className="text-danger">{OTPError}</div> : ''}
                                                    <div className="form-group text-center mar-b-0 ">
                                                        <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                                                        {disableButton == false ?<input type="button" onClick={pageUpdate} value="Submit OTP" className="btn btn-primary next" />:
                                                        <input type="button" value="Loading..." className="btn btn-primary next disabled" />}
                                                    </div>

                                                    {/* <OTPInput
                                                  value={OTP}
                                                  onChange={setOTP}
                                                  autoFocus
                                                  OTPLength={4}
                                                  otpType="number"
                                                  disabled={false}
                                                  secure
                                                />
                                                <ResendOTP handelResendClick={() => console.log("Resend clicked")} />        */}
                                                </div> 
                                            :
                                                <CustomerDetails pageUpdate={pageUpdate} pageDown={pageDown} customerDetails={customerDetails} customerDetailsValues={customerDetailsValues} backEnable={backEnable} categoryId={categoryId} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default InsuranceForms;