import React, { useState } from 'react';
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname } }) => {

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
      if(firstLoad == true){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
      setFirstLoad(false);
    }, [pathname]);

    return children || null;
};

export default withRouter(ScrollToTop);