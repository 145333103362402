import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PUBLIC_URL } from '../../../CommonString';
import SpinnerComponent from '../../common/SpinnerComponent';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';import Select from 'react-select';  
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated(); 

const CarModel = (props) => {

    const [model, setModel] = useState('');
    const [modelId, setModelId] = useState(props.modelValue ? props.modelValue : '');
    const [modelList, setModelList] = useState([]);
    const [modelError, setModelError] = useState(false);
    const [more, setMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [modelItems, setModelItems] = useState([]);

    const makeId = props.makeId;

    const pageUpdate = () => {
      if(modelId === ''){
        setModelError(true);
      }
      else{
        setModelError(false);
        props.pageUpdate();
      }
    }
    const pageDown = () => {
        props.pageDown();
    }

    const modelSelect = (models, id) => {
      setModel(models);
      setModelId(id);
      props.model(models, id);
      // pageUpdate();
    }

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      setLoading(true);
      axios({
        url: `${PUBLIC_URL}/model/list`,
        method: 'POST',
        data:{
          id: makeId,
        },
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'success'){
          setModelList(response.data.data);
          setModelItems((response.data.data ? response.data.data.map(data => ({ label: data.name, value: data.id })) : []));
        }
      }).catch((error) => {
        setLoading(false);
      });
    }, []);

    const modelChange = (e) => {

      // let modelText = e.nativeEvent.target.innerText;

      let modelText = e.label;
      let value = e.value;
      
      setModel(modelText);
      setModelId(value);

      props.model(modelText, value);
    }

    const onNothingClick = (e) => {

      e.preventDefault();
  }

    return (
      <>
        <SpinnerComponent open={loading} />
        <div className="form-container animated active flipInX">
          
          {/* {modelId ?<div className="selectd-params">
            {modelList.map((car, index) => {
              if(car.id === modelId)
                return <a href onClick={onNothingClick} key={index}>{car.name}</a>
              })}
          </div>: ''} */}
        
          <h2 className="text-center form-title">Car Model</h2>

          {/* <Autocomplete
            onChange={(e, modelItems) => modelChange(e, modelItems.value)}
            id="carModel_select"
            options={modelItems}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Car Model" />}
          /> */}
          <Select  onChange={modelChange}  options={modelItems} components={animatedComponents} />
          <br />
          {modelId ?   
          <div className="car-make-selected-div">
            <div className="car-make-selected-div-selected">
              <span className="currently-selected-year">Currently Selected:</span>
              {modelList.map((car, index) => {
                if(car.id === modelId)
                return <span className="selected-car-brand-img">{car.name}</span>
              })}
            </div>
          </div>
          :''}


          <div className="select-year">
            {more ? <div className="year-wrapper">
              {modelList && modelList.map((list, index) => {
                if(index < 9)
                  return <div key={index} className="year-box-for-car-model image-text" onClick={() => modelSelect(list.name, list.id)}><a className="model-link-href" href onClick={onNothingClick}>{list.name}</a></div>
              })}
              
            </div>
            :
            <div className="year-wrapper" style={{overflowY: 'auto', maxHeight: '40vh'}}>
              {modelList && modelList.map((list, index) => {
                return <div key={index} className="year-box-for-car-model image-text" onClick={() => modelSelect(list.name, list.id)}><a className="model-link-href" href onClick={onNothingClick}>{list.name}</a></div>
              })}
              
            </div>
            }
          </div>     
          {modelError ? <div style={{color: 'red'}}>Model is required</div> : ''}
          <div className="form-group">
          </div>
          <button onClick={() => setMore(!more)} id="myBtn">{more ? 'Show more' : 'Show less' }</button>
          <div className="form-group text-center mar-b-0"> 
            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" /> 
            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />      
          </div>
        </div>
        
        </>
    );
};

export default CarModel;