import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from '../../layout/english/Footer';
import Header from '../../layout/english/Header';
import CarDetails from './CarDetails';
import CarMake from './CarMake';
import CarModel from './CarModel';
import CarVariant from './CarVariant';
import CustomerDetails from './CustomerDetails';
import ModelYear from './ModelYear';
import $ from 'jquery';
import Swal from 'sweetalert2';
import axios from 'axios';
import { PUBLIC_URL } from '../../../CommonString';
import SpinnerComponent from '../../common/SpinnerComponent';
import OTPInput, { ResendOTP } from "otp-input-react";

// let toDayDate = new Date();
// console.log(toDayDate);
// for(let i = new Date; i <= new Date())
// var availableDates = ["9-11-2021","10-11-2021","12-11-2021"];

// function available(date) {
//   dmy = date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
//   if ($.inArray(dmy, availableDates) == 1) {
//     return [true, "","Available"];
//   } else {
//     return [false,"","unAvailable"];
//   }
// }

// $('#date').datepicker({ beforeShowDay: available });

const Main = withRouter(({history, props}) => {

  const [page, setPage] = useState(1);
  const [details, setDetails] = useState({
    claime: '',
    category: '',
    vehicleType: '',
    licence: '',
    modelYear: '',
    make: '',
    makeId: '',
    model: '',
    modelId: '',
    variant: '',
    variantId: '',
    name: '',
    email: '',
    mobile: '',
    day: '',
    month: '',
    year: '',
    dob: '',
    gender: '',
    nationality: '',
    nationalityId: '',
    previousInsurer: '',
    registationCity: '',
    uaeDrivingExperiance: '',
    internationalDrivingExperiance: '',
    noClaimeCirtificate: '',
    claimeCirtificateFile: '',
    carValue: '',
    insurenceType: '',
  });
  const [previousInsurerError, setPreviousInsurerError] = useState(false);
  const [registrationCityError, setRegistrationCityError] = useState(false);
  const [typeOfInsuranceError, setTypeOfInsuranceError] = useState(false);
  const [carValueError, setCarValueError] = useState(false);
  const [carValueErrorText, setCarValueErrorText] = useState('');
  const [uaeDirvingError, setUaeDirvingError] = useState(false);
  const [claimeError, setClaimeError] = useState(false);
  const [claimeDocumentError, setClaimeDocumentError] = useState(false);
  const [fileName, setFileName] = useState('');
  const [loadeing, setLoading] = useState(false);

  const [categoryId, setCategoryId]  = useState(1);
  const [ipAddress, setIpAddress] = useState('');
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [verifiedOtp, setVerifiedOtp] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const insurenceTypes = [
    {
      value: 'Comprehensive',
      text: 'Comprehensive (full insurance)',
    },
    {
      value: 'Third Party Only',
      text: 'Third Party Only',
    },
  ];

  const insurerList = [
    {
      value: 'Other',
    },{
      value: 'DINAR',
    },
    {
      value: 'Tokio Marine',
    },
    {
      value: 'Watania',
    },
    {
      value: 'Salama Insurance',
    },
    {
      value: 'Noor Takaful',
    },
    {
      value: 'Oriental',
    },
    {
      value: 'Adamjee',
    },
    {
      value: 'Union Insurance',
    },
  ];

  const cityList = [
    {
      value: 'Dubai',
    },
    {
      value: 'Sharjah',
    },
    {
      value: 'Abu Dhabi',
    },
    {
      value: 'Ras Al Khaimah',
    },
    {
      value: 'Al Ain',
    },
    {
      value: 'Ajman',
    },
    {
      value: 'Fujairah',
    },
    {
      value: 'Umm Al Quwain',
    },
  ];

  const drivingExperiance = [
    {
      value: 'lessthan-1-year',
      text: 'Less than 1 Year',
    },
    {
      value: '1-2 Years',
      text: '1 - 2 Years',
    },
    {
      value: '2-3 Years',
      text: '2 - 3 Years',
    },
    {
      value: '3-4 Years',
      text: '3 - 4 Years',
    },
    {
      value: '4-5 Years',
      text: '4 - 5 Years',
    },
    {
      value: 'Above 5 Years',
      text: 'Above 5 Years',
    },
  ];

  const claimList = [
    {
      value: 'never',
      text: 'Never',
    },
    {
      value: '0-1-year',
      text: '0 -1 Years Ago',
    },
    {
      value: '1-2-year',
      text: '1 -2 Years Ago',
    },
    {
      value: '2-3-year',
      text: '2 -3 Years Ago',
    },
    {
      value: '3-4-year',
      text: '3 -4 Years Ago',
    },
    {
      value: '4-5-year',
      text: '4 -5 Years Ago',
    },
    {
      value: 'morthan-5-years',
      text: 'More than 5 Years Ago',
    },
  ]

  
  let dateObj = new Date(details.modelYear + 1, 1, 1);
  let nextYear = dateObj.getFullYear();
  let dateObj1 = new Date(details.modelYear - 1, 1, 1);
  let preYear = dateObj1.getFullYear();

  const registrationYearList = [
    {
      value: nextYear,
    },
    {
      value: details.modelYear,
    },
    {
      value: preYear,
    },
  ];

  const pageUpdate = () => {

    if(page === 10){
     
      if(details.name !== '' && details.email !== '' && details.mobile !== '' && details.dob !== '' && details.nationalityId !== '' && details.gender !== ''){
        
        let otpData = new FormData();
        otpData.append('mobile', details.mobile);

        axios({
          url: `${PUBLIC_URL}/generate/otp`,
          method: 'POST',
          data: otpData,

        }).catch((error) => {});
        
        setPage(11);
      }
      else{
      }
    }
    else if(page === 11){

      let verifyData = new FormData();
      let verified_otp = '';

      verifyData.append('mobile', details.mobile);
      verifyData.append('otp', OTP);

      setLoading(true);
      setDisableButton(true);

      axios({
        url: `${PUBLIC_URL}/verify/otp`,
        method: 'POST',
        data: verifyData,

      }).then(response => {

        if(response.data.status === 'success'){

          setVerifiedOtp(response.data.otp);
          verified_otp = response.data.otp;

          setLoading(true);

          if(OTP == verified_otp){

            setOTPError('');
            
            let formData = new FormData();
            formData.append('category', categoryId);
            formData.append('ip_address', ipAddress);
            formData.append('name', details.name ? details.name : null);
            formData.append('email', details.email ? details.email : null);
            formData.append('phone', details.mobile ? details.mobile : null);
            formData.append('gender', details.gender ? details.gender : null);
            formData.append('nationality', details.nationalityId ? details.nationalityId : null);
            formData.append('date_of_berth', details.dob ? details.dob : null);
            formData.append('model_year', details.modelYear ? details.modelYear : null);
          
            formData.append('make', details.makeId ? details.makeId : null);
            formData.append('model', details.modelId ? details.modelId : null);
        
            formData.append('previous_insurer', details.previousInsurer ? details.previousInsurer : null);
            formData.append('registration_city', details.registationCity ? details.registationCity : null);
            formData.append('first_registration_year', details.firstRegistrationYear ? details.firstRegistrationYear : 0);
            formData.append('uae_driving_experience', details.uaeDrivingExperiance ? details.uaeDrivingExperiance : null);
       
            formData.append('international_driving_experience', details.internationalDrivingExperiance ? details.internationalDrivingExperiance : null);
            formData.append('when_did_clime', details.claime ? details.claime : null);
            formData.append('have_no_claime_cirtificate', details.noClaimeCirtificate ? details.noClaimeCirtificate : null);
            formData.append('claime_certificate', details.claimeCirtificateFile ? details.claimeCirtificateFile : null);
            formData.append('carvalues', details.carValue ? details.carValue : 0);
            formData.append('insuranceType', details.insurenceType ? details.insurenceType : null);
                            
            axios({
              url: `${PUBLIC_URL}/enquiry/store`,
              method: 'POST',
              data: formData,
            }).then(response => {
    
              setLoading(false);
    
              if(response.data.status === 'success'){
                if(details.uaeDrivingExperiance === 'lessthan-1-year'){
    
                  Swal.fire({
                    title: '<strong>Thank You</strong>',
                    icon: 'success',
                    html:
                      'One of our Member is working on your custom quote and will be in touch shortly.',
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonText:
                      '<i className="fa fa-thumbs-up"></i> Go to home page!',
                    confirmButtonAriaLabel: 'Thumbs up, great!',
                   
                  }).then((result) => {
                    history.push('/');
                  });
                  
                }
                else{
                  Swal.fire({
                    title: '<strong>Thank You</strong>',
                    icon: 'success',
                    html:
                      'One of our Member is working on your custom quote and will be in touch shortly.',
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonText:
                      '<i className="fa fa-thumbs-up"></i> Go to home page!',
                    confirmButtonAriaLabel: 'Thumbs up, great!',
                   
                  }).then((result) => {
                    history.push('/');
                  });
                }
              }
              else{
                Swal.fire({
                  title: 'error!',
                  text: response.data.message,
                  icon: 'error',
                });
              }
    
            }).catch((error) => {
              setLoading(false);
              Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
              });
              
            });
    
          }
        }
        else{
          setDisableButton(false);
          setLoading(false);
          setOTPError(response.data.message);
        }

        

      }).catch((error) => {
        setLoading(false);
      });
      
    }
    else{

      if(page == 4){
        // if(details.previousInsurer === ''){
        //   setPreviousInsurerError(true);
        // }
        // else{
          setPage(page + 1);
        // }
      }
      else if(page == 5){
        if(details.registationCity === '' && details.insurenceType === ''){
          setRegistrationCityError(true);
          setTypeOfInsuranceError(true);
        }
        else if(details.insurenceType === ''){
          setTypeOfInsuranceError(true);
        }
        else if(details.registationCity === ''){
          setRegistrationCityError(true);
        }
        else{

          if(details.insurenceType === 'Comprehensive'){
            if(details.carValue === ''){
              setCarValueError(true);
            }
            else{
              if(details.carValue > 0){

                setRegistrationCityError(false);
                setTypeOfInsuranceError(false);
                setCarValueError(false);
                setPage(page + 1);
              }
              else{
                setCarValueError(true);
              }
            }
          }
          else{
            if(details.carValue === ''){
              setRegistrationCityError(false);
              setTypeOfInsuranceError(false);
              setCarValueError(false);
              setPage(page + 1);
            }
            else{
              if(details.carValue > 0){
                setRegistrationCityError(false);
                setTypeOfInsuranceError(false);
                setCarValueError(false);
                setPage(page + 1);
              }
              else{
                setCarValueError(true);
              }
            }
            
          }
        }
      }
      else if(page == 6){
        if(details.uaeDrivingExperiance === ''){
          setUaeDirvingError(true);
        }
        else{
          setPage(page + 1);
        }
      }
      else if(page == 7){
        if(details.claime === ''){
          setClaimeError(true);
        }
        else{
          if(details.claime === 'never'){
            setPage(page + 1);
          }
          else{
            setPage(10);
          }
        }
      }
      else if(page == 9){
        if(details.claimeCirtificateFile === ''){
          setClaimeDocumentError(true);
        }
        else{
          setPage(page + 1);
        }
      }
      else{
        setPage(page + 1);
      }
    }

  }

  const pageUpdateToFinal = () => {
    setPage(10);
  }

  const pageDown = () => {

    let date = new Date();
    let year = date.getFullYear();
    
    if(page == 10){
      if(details.uaeDrivingExperiance === 'lessthan-1-year'){
        setPage(6);
      }
      else if(details.claime == 'never'){
        if(details.noClaimeCirtificate == 'yes'){
          setPage(page - 1);
        }
        else{
          setPage(8);
        }
      }
      else{
        setPage(7);
      }
    }
    else{
      setPage(page - 1);
    }
  }


  const modelYear = (year) => {
    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: year,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    setPage(page + 1);
  }
  
  const make = (make, id) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: make,
      makeId: id,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    pageUpdate();
  }

  const model = (model, id) => {
    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: model,
      modelId: id,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    pageUpdate();
  }

  const variant = (variant, id) => {
    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: variant,
      variantId: id,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    pageUpdate();

  }

  const customerDetails = (customer) => {
    
    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: customer.name,
      email: customer.email,
      mobile: customer.mobile,
      day: customer.day,
      month: customer.month,
      year: customer.year,
      dob: customer.dob,
      gender: customer.gender,
      nationality: customer.nationality,
      nationalityId: customer.nationalityId,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });
  }

  let customerDetailsValues = {
    name: details.name,
    email: details.email,
    mobile: details.mobile,
    day: details.day,
    month: details.month,
    year: details.year,
    dob: details.dob,
    gender: details.gender,
    nationality: details.nationality,
    nationalityId: details.nationalityId,
  }

  const previousInsurerChange = (e) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      previousInsurer: e.target.value,
      registationCity: details.registationCity,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    setPreviousInsurerError(false);

    pageUpdate();
  }

  const registrationCityChange = (e) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      previousInsurer: details.previousInsurer,
      registationCity: e.target.value,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

  }

  const uaeDrivingExperianceChange = (e) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      buyingSecondHand: details.buyingSecondHand,
      anotherCar: details.anotherCar,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      firstRegistrationYear: details.firstRegistrationYear,
      uaeDrivingExperiance: e.target.value,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    setUaeDirvingError(false);

    if(e.target.value === 'lessthan-1-year'){
      pageUpdateToFinal();
    }
    // else{
    //   pageUpdate();
    // }
  }

  const internationalDrivingExperianceChange = (e) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      buyingSecondHand: details.buyingSecondHand,
      anotherCar: details.anotherCar,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      firstRegistrationYear: details.firstRegistrationYear,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: e.target.value,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    // pageUpdate();
  }

  const claimeChage = (e) => {

    setDetails({
      claime: e.target.value,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      buyingSecondHand: details.buyingSecondHand,
      anotherCar: details.anotherCar,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      firstRegistrationYear: details.firstRegistrationYear,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    setClaimeError(false);

    if(e.target.value === 'never'){
      setPage(page + 1);
    }
    else{
      setPage(10);
    }
  }

  const noClaimeCirtificateChange = (e) => {
    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      buyingSecondHand: details.buyingSecondHand,
      anotherCar: details.anotherCar,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      firstRegistrationYear: details.firstRegistrationYear,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: e.target.value,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    if(e.target.value === 'yes'){
      pageUpdate();
    }
    else{
      setPage(10);
    }
  }

  const cirtificateUpload = (e) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      buyingSecondHand: details.buyingSecondHand,
      anotherCar: details.anotherCar,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      firstRegistrationYear: details.firstRegistrationYear,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: e.target.files[0],
      carValue: details.carValue,
      insurenceType: details.insurenceType,
    });

    setFileName(e.target.files[0].name);

    setClaimeDocumentError(false);

  }

  const carValueChange = (e) => {
    
    if(e.target.value < 0){
      setCarValueError(true);
      setCarValueErrorText('Please enter a valid car value');
    }
    else{
      setCarValueError(false);
      setCarValueErrorText('');
    }

      setDetails({
        claime: details.claime,
        category: details.category,
        vehicleType: details.vehicleType,
        licence: details.licence,
        modelYear: details.modelYear,
        make: details.make,
        makeId: details.makeId,
        model: details.model,
        modelId: details.modelId,
        variant: details.variant,
        variantId: details.variantId,
        name: details.name,
        email: details.email,
        mobile: details.mobile,
        day: details.day,
        month: details.month,
        year: details.year,
        dob: details.dob,
        gender: details.gender,
        nationality: details.nationality,
        nationalityId: details.nationalityId,
        buyingSecondHand: details.buyingSecondHand,
        anotherCar: details.anotherCar,
        previousInsurer: details.previousInsurer,
        registationCity: details.registationCity,
        firstRegistrationYear: details.firstRegistrationYear,
        uaeDrivingExperiance: details.uaeDrivingExperiance,
        internationalDrivingExperiance: details.internationalDrivingExperiance,
        noClaimeCirtificate: details.noClaimeCirtificate,
        claimeCirtificateFile: details.claimeCirtificateFile,
        carValue: e.target.value,
        insurenceType: details.insurenceType,
      });
  }

  const insuranceType = (e) => {

    setDetails({
      claime: details.claime,
      category: details.category,
      vehicleType: details.vehicleType,
      licence: details.licence,
      modelYear: details.modelYear,
      make: details.make,
      makeId: details.makeId,
      model: details.model,
      modelId: details.modelId,
      variant: details.variant,
      variantId: details.variantId,
      name: details.name,
      email: details.email,
      mobile: details.mobile,
      day: details.day,
      month: details.month,
      year: details.year,
      dob: details.dob,
      gender: details.gender,
      nationality: details.nationality,
      nationalityId: details.nationalityId,
      buyingSecondHand: details.buyingSecondHand,
      anotherCar: details.anotherCar,
      previousInsurer: details.previousInsurer,
      registationCity: details.registationCity,
      firstRegistrationYear: details.firstRegistrationYear,
      uaeDrivingExperiance: details.uaeDrivingExperiance,
      internationalDrivingExperiance: details.internationalDrivingExperiance,
      noClaimeCirtificate: details.noClaimeCirtificate,
      claimeCirtificateFile: details.claimeCirtificateFile,
      carValue: details.carValue,
      insurenceType: e.target.value,
    });
  }

  const sendOtp = () => {

    let otpData = new FormData();
    otpData.append('mobile', details.mobile);

    setLoading(true);

    axios({
      url: `${PUBLIC_URL}/generate/otp`,
      method: 'POST',
      data: otpData,

    }).then(response => {

      if(response.data.status == 'success'){

      }

      setLoading(false);

    }).catch((error) => {
      setLoading(false);
    });
    
  }

  const mystyle = {
    width: "50px",
    height: "50px",
    backgroundColor: "lighten(#0f0f1a, 5%)",
    // border: "none",
    lineHeight: "50px",
    textAlign: "center",
    fontSize: "24px",
    display: "initial",
    fontWeight: "200",
    color: "#172f3b",
    margin:"0 2px",
  };

  const renderButton = (buttonProps) => {
    return (
      <button {...buttonProps}>
        {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : <a onClick={sendOtp}>Resend</a>}
      </button>
    );
  }

  const renderTime = () => React.Fragment;
  
  return (
    
    <>
      <Header />
      <SpinnerComponent open={loadeing} />
        <section className="car-make">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="steps-form">
                  <div className="form-wizard">
                  <div className="steps"></div>
                    <div className="myContainer" style={{minWidth: '30vw', minHeight: '60vh'}}>
                    { page == 1 ?
                      <ModelYear pageUpdate={pageUpdate} pageDown={pageDown} modelYear={modelYear} modelYearValue={details.modelYear} />
                      : page == 2 ?
                      <CarMake pageUpdate={pageUpdate} pageDown={pageDown} make={make} makeValue={details.makeId} />
                      : page == 3 ?
                      <CarModel pageUpdate={pageUpdate} pageDown={pageDown} model={model} makeId={details.makeId} modelValue={details.modelId} />
                      // : page == 4 ?
                      // <CarVariant pageUpdate={pageUpdate} pageDown={pageDown} variant={variant} modelId={details.modelId} variantValue={details.variantId} />
                      : page == 4 ?
                      <>
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">Car Details</h2>
                          <select className="custom-select mb-3" onChange={previousInsurerChange}>
                            <option>Previous Insurer (optional)</option>
                            {insurerList.map((list, index) => {
                              if(list.value === details.previousInsurer)
                                return <option selected key={index} value={list.value}>{list.value}</option>
                              else
                                return <option key={index} value={list.value}>{list.value}</option>
                            })}
                          </select>
                          {previousInsurerError ? <div style={{color: 'red'}}>Previous Insurer is required</div> : ''}
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />        
                          </div>
                        </div>
                      </>
                      : page == 5 ? 
                      <>
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">Registration details</h2>
                          <select className="custom-select mb-3" onChange={registrationCityChange}>
                            <option value="" selected>Registration city</option>
                            {cityList.map((list, index) => {
                              if(list.value === details.registationCity)
                                return <option selected key={index} value={list.value}>{list.value}</option>
                              else
                                return <option key={index} value={list.value}>{list.value}</option>
                            })}
                          </select>
                          {registrationCityError ? <div style={{color: 'red'}}>Registration city is required</div> : ''}
                          <select className="custom-select mb-3" onChange={insuranceType}>
                            <option value="">Type of insurance</option>
                            {insurenceTypes.map((types, index) => {
                              if(types.value === details.insurenceType){
                                return <option key={index} selected value={types.value}>{types.text}</option>
                              }
                              else{
                                return <option key={index} value={types.value}>{types.text}</option>
                              }
                            })}
                          </select>
                          {typeOfInsuranceError ? <div style={{color: 'red'}}>Type of insurance is required</div> : ''}
                          <div className="form-group">
                            <input style={{height: 'calc(2.25rem + 2px)'}} onChange={carValueChange} type="number" value={details.carValue} className="form-control" placeholder="Car Value" />
                            {carValueError ? <div style={{color: 'red'}}>{carValueErrorText !== '' ? carValueErrorText : 'Car value is required'}</div> : ''}
                          </div>
                          <div>
                          </div>
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />     
                          </div>
                        </div>
                      </>
                      : page == 6 ?
                      <>
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">License details</h2>
                          <select className="custom-select mb-3" onChange={uaeDrivingExperianceChange}>
                            <option>UAE driving experience</option>
                            {drivingExperiance.map((driving, index) => {
                              if(details.uaeDrivingExperiance === driving.value){
                                return <option selected key={index} value={driving.value}>{driving.text}</option>
                              }
                              else{
                                return <option key={index} value={driving.value}>{driving.text}</option>
                              }
                            })}
                            
                          </select>
                          {uaeDirvingError ? <div style={{color: 'red'}}>UAE driving experience is required</div> : ''}
                          {/* <select className="custom-select mb-3" onChange={internationalDrivingExperianceChange}>
                            <option>International driving experience</option>
                            {drivingExperiance.map((driving, index) => {
                              if(details.internationalDrivingExperiance === driving.value){
                                return <option selected key={index} value={driving.value}>{driving.text}</option>
                              }
                              else{
                                return <option key={index} value={driving.value}>{driving.text}</option>
                              }
                            })}
                          </select> */}
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />        
                          </div>
                        </div>
                      </>
                      : page == 7 ?
                      <>
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">Claim details</h2>
                          <select className="custom-select mb-3" onChange={claimeChage}>
                            <option>When did you claim your car insurance</option>
                            {claimList.map((item, index) => {
                              if(item.value === details.claime){
                                return <option key={index} selected value={item.value}>{item.text}</option>
                              }
                              else{
                                return <option key={index} value={item.value}>{item.text}</option>
                              }
                            })}
                            
                          </select>
                          {claimeError ? <div style={{color: 'red'}}>Claim details is required</div> : ''}
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />        
                          </div>
                        </div>
                      </>
                      : page == 8 ?
                      <>
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">Car Detail</h2>
                          <label>Do you have no claim cirtificate ?</label>
                          <div className="switch-field">
                            {details.noClaimeCirtificate === 'yes' ?
                            <>
                              <input type="radio" id="radio-nine" onClick={noClaimeCirtificateChange} checked name="noClaime" value="yes" autoComplete="off" />
                              <label for="radio-nine">Yes</label>
                              <input type="radio" id="radio-ten" onClick={noClaimeCirtificateChange} name="noClaime" value="no" autoComplete="off" />
                              <label for="radio-ten">No</label>
                            </>
                            : details.noClaimeCirtificate === 'no' ?
                            <>
                              <input type="radio" id="radio-nine" onClick={noClaimeCirtificateChange} name="noClaime" value="yes" autoComplete="off" />
                              <label for="radio-nine">Yes</label>
                              <input type="radio" id="radio-ten" onClick={noClaimeCirtificateChange} checked name="noClaime" value="no" autoComplete="off" />
                              <label for="radio-ten">No</label>
                            </>
                            :
                            <>
                              <input type="radio" id="radio-nine" onClick={noClaimeCirtificateChange} name="noClaime" value="yes" autoComplete="off" />
                              <label for="radio-nine">Yes</label>
                              <input type="radio" id="radio-ten" onClick={noClaimeCirtificateChange} name="noClaime" value="no" autoComplete="off" />
                              <label for="radio-ten">No</label>
                            </>}
                          </div>
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            {/* <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" /> */}
                          </div>
                        </div>
                      </>
                      : page == 9 ?
                      <>
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">Car Detail</h2>
                          <div className="form-group">
                            <label>Upload No Claim Certificate Document</label>
                            <div className="custom-file">
                              <input type="file" name="files" onChange={cirtificateUpload} className="custom-file-input form-control" id="customFile" />
                              <label className="custom-file-label" for="customFile">{fileName ? fileName : 'Choose a File' }</label>
                            </div>
                            {claimeDocumentError ? <div style={{color: 'red'}}>No claim certificate is required</div> : ''}
                          </div>
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />
                          </div>
                        </div>
                      </>
                      : page == 10 ?
                      <CustomerDetails pageUpdate={pageUpdate} pageDown={pageDown} customerDetails={customerDetails} customerDetailsValues={customerDetailsValues} backEnable={true} categoryId={categoryId} />
                      : page == 11 ?
                      <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">Enter OTP</h2>
                          <OTPInput 
                            value={OTP} 
                            onChange={setOTP}
                            autoFocus OTPLength={6} 
                            otpType="number"
                            disabled={false} 
                            inputStyles={mystyle}
                          />
                        
                          <ResendOTP renderButton={renderButton} renderTime={renderTime} />
                          {OTPError ? <div className="text-danger">{OTPError}</div> : '' }
                          <div className="form-group text-center mar-b-0 ">
                            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                            {disableButton == false ?<input type="button" onClick={pageUpdate} value="Submit OTP" className="btn btn-primary next" />:
                            <input type="button" value="Loading..." className="btn btn-primary next disabled" />}
                          </div> 
                      </div>
                      : ''
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  );
});

export default Main;