import React, { useEffect, useState } from 'react';

let years = [];
let date = new Date();
let nextDate = new Date(date.getFullYear() + 1, 1, 1);
let thisYear = nextDate.getFullYear();

for(let i = 0; i < 27; i++){
  
  date = new Date(thisYear - 1, 1, 1);
  thisYear = date.getFullYear();

  let year = {
    year: thisYear,
  };

  years = [...years, year];

}


const ModelYear = (props) => {

  const [year, setYear] = useState(props.modelYearValue ? props.modelYearValue : '');
  const [errorYear, setErrorYear] = useState(false);
  const [more, setMore] = useState(true);
  
    const pageDown = () => {
      props.pageDown();
    }

    const yearChange = (e) => {
      setYear(e.target.value);
      props.modelYear(e.target.value);
    }

    const yearSelect = (newyear) => {
      
      setYear(newyear);
      props.modelYear(newyear);
      
    }

    const pageUpdate = () => {
      if(year === ''){
        setErrorYear(true);
      }
      else{
        setErrorYear(false);
        props.pageUpdate();
      }
    }

    useEffect(() => {
     
    }, [errorYear, year])

    const onNothingClick = (e) => {

      e.preventDefault();
  }
    
    return (

        <div className="form-container animated active flipInX">
          {/* {year ? <div className="view" style={{top: '23px'}}>
            <a href onClick={onNothingClick}>{year}</a>
          </div> : ''} */}
          <h2 className="text-center form-title">Select Model Year</h2>
          <select name="format" onChange={yearChange} id="format">
            <option>Select Model Year</option>
              {years.map((years, index) => {
                if(years.year === year){
                  return <option selected key={index} value={years.year}>{years.year}</option>
                }
                else{
                  return <option key={index} value={years.year}>{years.year}</option>
                }
              })}
          </select>
          <div className="select-year">
            {year ? 
              <div className="currently-selected-year">
                <span className="currently-selected-year">Currently Selected:</span>
                <div className="currently-selected-year-val">{year}</div>
              </div>
              : ''}
            {more ? <div className="year-wrapper" >
              {years.map((years, index) => {
                if(index < 9)
                  return <div key={index} onClick={() => yearSelect(years.year)} className="year-box-caryear image-text"><a href onClick={onNothingClick} style={{textDecoration: 'none'}}>{years.year}</a></div>
              })}
            </div>
            :
            <div className="year-wrapper" style={{overflowY: 'auto', maxHeight: '23vh'}}>
                {years.map((years, index) => {
                    return <div key={index} onClick={() => yearSelect(years.year)} className="year-box-caryear image-text"><a href onClick={onNothingClick} style={{textDecoration: 'none'}}>{years.year}</a></div>
                })}
            </div>
            }
            {/* <span id="dots"></span>
            <div  id="more">
              <div className="year-wrapper">
                {years.map((years, index) => {
                  if(index >= 9)
                    return <div key={index} onClick={() => yearSelect(years.year)} className="year-box image-text"><a href onClick={onNothingClick}>{years.year}</a></div>
                })}
              </div>
            </div> */}
            {errorYear ? <div style={{color: 'red'}}>Model year is required</div> : ''}
            <button onClick={() => setMore(!more)} id="myBtn">{more ? 'Show more' : 'Show less' }</button>
          </div>
          <div className="form-group text-center mar-b-0">
            {/* <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />  */}
            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />
          </div>
        </div>
    );
};

export default ModelYear;