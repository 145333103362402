import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavContent from './NavContent';

const HomeHeader = (props) => {

    const [headeClass, setHeaderClass] = useState(false);

    useEffect(() => {

        init();
    });

    const init = () => {
        window.addEventListener('scroll', function(e){
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 30;
                // header = document.querySelector("header");
            if (distanceY > shrinkOn) {
                setHeaderClass(true);
            } else {
                if(headeClass == true){
                    setHeaderClass(false);
                }
            }
        });
    }

    return (
        <header className={headeClass == true ? 'smaller' : ''}>
            <NavContent scrollToRef={props.scrollToRef} />
        </header>
    );
};

export default HomeHeader;