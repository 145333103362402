import React, { useEffect } from 'react';
import Footer from '../../layout/english/Footer';
import Header from '../../layout/english/Header';
import CommunicationChannel from './CommunicationChannel';
import ComplaintForm from './ComplaintForm';
import Procedure from './Procedure';

const Complaint = () => {

    useEffect(() => {
        
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {

            window.scrollTo({ 
                behavior: 'smooth',
                top: 0,
                left: 0 
            });
        }
    });

    return (
        <div>
            <Header />
            <section className="complaints">
                <div className="container">
                    <h1>Complaints</h1>
                </div>
            </section>
            <Procedure />

            <CommunicationChannel />

            <ComplaintForm />
            <Footer />
        </div>
    );
};
export default Complaint;