import React, { useState } from 'react';
import { Container, Dropdown, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useHistory, } from 'react-router-dom';

const NavContent = (props) => {

    const [navExpanded, setNavExpanded] = useState(false);

    let history = useHistory();

    const scrollToRef = (value) => {
        setNavExpanded(!navExpanded);
        props.scrollToRef({'key': value});
    }

    let location = window.location.href.split('#');

    const toHome = () => {
        setNavExpanded(!navExpanded);
        history.push('/');
    }
        
    return (
        <div className="container clearfix">
            <Navbar expand="lg" expanded={navExpanded}>
                <Link className="navbar-brand" to="/#home" id="logo" onClick={location[1] === '/' ? () => scrollToRef('home') : toHome}><img className="main-logo" src="images/beemabazaar-logo.png" /></Link>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={() => setNavExpanded(!navExpanded)} />
                    <Navbar.Collapse id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item active"><Link className="" onClick={location[1] === '/' ? () => scrollToRef('home') : toHome} to="/#home">Home</Link></li>
                            <li className="nav-item active"><Link className="" onClick={location[1] === '/' ? () => scrollToRef('about') : toHome} to="/#about">About</Link></li>
                            <li className="nav-item active"><Link className="" onClick={location[1] === '/' ? () => scrollToRef('partner') : toHome} to="/#partners">Partners</Link></li>
                            <li className="nav-item active"><Link className="" onClick={location[1] === '/' ? () => scrollToRef('review') : toHome} to="/#reviews">Reviews </Link></li>
                            <li className="nav-item active">
                                <Dropdown >
                                    <Dropdown.Toggle variant="secondary" className="nav-complaint" style={{padding: '0', margin: '0', fontFamily: 'PT Serif, serif', borderColor: 'transparent', boxShadow: 'none'}}>
                                        Complaints
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/complaints">To Beemabazaar</Dropdown.Item>
                                        <Dropdown.Item target="_blank" href="http://ipromes.eclaimlink.ae/">To DHA</Dropdown.Item>
                                        <Dropdown.Item target="_blank" href="https://smartservices.ia.gov.ae/EComplaint/SubmitNewDispute">To Insurance Authority</Dropdown.Item>
                                    </Dropdown.Menu>
                                    
                                </Dropdown>
                            </li>
                            <li className="nav-item active"><Link className="" to="/contactus">Contact Us</Link></li>
                            {/* <li className="nav-item active"><Link className="" to="#contact"><i className="fa fa-user-o mr-1"></i>Log in</Link></li> */}
                            <div className="happy">
                                <img src="images/whatsapp.png" style={{height: '35px'}} className="img-fluid" />
                                <p> Toll Free<br />
                                800 OMEGA (66342)</p>
                            </div>
                            {/* <div className="lang">
                                <Dropdown >
                                    <Dropdown.Toggle variant="secondary" className="btn btn-secondary" style={{padding: '1px 0', borderColor: 'transparent', boxShadow: 'none'}}>
                                        <img src="images/index.png" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item>Engilsh</Dropdown.Item>
                                        <Dropdown.Item>Araba</Dropdown.Item>
                                    </Dropdown.Menu>
                                    
                                </Dropdown>
                            </div> */}
                        </ul>
                    </Navbar.Collapse>
                
            </Navbar>
        </div>
    );
};

export default NavContent;