import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../../CommonString';
import InsuranceItem from './InsuranceItem';

const Insurance = (props) => {

    const category = props.category;
    let groups = [];

    if(category.length > 3){
        
        const chunkSize = 4;
        const arr = category;
        groups = arr.map((e, i) => {
            return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;

        }).filter(e => {
            return e;
        });
        
    }
    else{

        groups = [...groups, category];
    }

    return (

        <section className="insurance-bg">
            <div className="watsapp">
                <div className="fixed-tabs-Whats">
                    <div className="aba-whatsphone">
                        <div className="aba-whatsphone-icone">
                            <a href="https://wa.me/+971503181285" target="_blank">Beemabazaar <br /><strong>On Whatsapp!</strong></a>          
                        </div>
                    </div>          
                </div>
            </div>
            <div className="container">
                <div className="insurance-top">
                    <h1>WE TAKE THE RISK OUT OF INSURANCE</h1>
                    <div className="row">

                        <div className="col-md-4">
                            {category.length > 0 && category.map((category, index) => {
                                if(category.id == 1 && index == 0){
                                    return(

                                        <div className="insurance-box-contant" key={index}>
                                            <img src={IMAGE_URL + '/' + category.image}  className="img-fluid" />
                                            <h4>{category.name}</h4>
                                            <div className="btn">
                                                <Link to={'/car-insurance'} className="hvr-sweep-to-right">
                                                    <img src="/images/masscart-man.png" className="img-fluid" style={{maxWidth: '20px', marginTop: '-6px'}} />
                                                    Get Quotes
                                                </Link>
                                            </div>
                                        </div>
                                        
                                    );
                                }
                            })}
                            
                        </div>
                        <div className="col-md-8">
                        {category && category.length > 0 ?
                        
                        <div className="row">
                            {category && category.map((item, index) => {
                                if(item.id !== 1 && index < 5){
                                    if(item.id == 2){
                                        return (
                                            <div className="col-md-3" key={index}>
                                                <div className="insurance-contant-box-text">
                                                    <img src={IMAGE_URL + '/' + item.image} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                    <Link to={'/health-insurance?category=' + item.name + '&id=' + item.id} className="hvr-sweep-to-right">
                                                        <img src="/images/masscart-man.png" className="img-fluid" style={{maxWidth: '20px', marginTop: '-6px'}} />
                                                        Get Quotes
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else{
                                        return (
                                            <div className="col-md-3" key={index}>
                                                <div className="insurance-contant-box-text">
                                                    <img src={IMAGE_URL + '/' + item.image} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                    <Link to={'/insurance/form/'+item.canonical_name + '?category=' + item.name + '&id=' + item.id} className="hvr-sweep-to-right">
                                                        <img src="/images/masscart-man.png" className="img-fluid" style={{maxWidth: '20px', marginTop: '-6px'}} />
                                                        Get Quotes
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                
                            })}
                            
                        </div>
                        : ''}

                        {category.length > 5 ?
                        <div className="row">
                            {category && category.map((item, index) => {
                                if(item.id !== 1 && index > 4){
                                    if(item.id == 2){
                                        return (
                                            <div className="col-md-3" key={index}>
                                                <div className="insurance-contant-box-text">
                                                    <img src={IMAGE_URL + '/' + item.image} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                    <Link to={'/health-insurance?category=' + item.name + '&id=' + item.id} className="hvr-sweep-to-right">
                                                        <img src="/images/masscart-man.png" className="img-fluid" style={{maxWidth: '20px', marginTop: '-6px'}} />
                                                        Get Quotes
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else{
                                        
                                        return (
                                            <div className="col-md-3" key={index}>
                                                <div className="insurance-contant-box-text">
                                                    <img src={IMAGE_URL + '/' + item.image} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                    <Link to={'/insurance/form/'+item.canonical_name + '?category=' + item.name + '&id=' + item.id} className="hvr-sweep-to-right">
                                                        <img src="/images/masscart-man.png" className="img-fluid" style={{maxWidth: '20px', marginTop: '-6px'}} />
                                                        Get Quotes
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                
                            })}
                            
                        </div> : ''}
                        
                        {/* {groups && groups.map((category, index) => {
                            console.log(category);
                                return (
                                    <div className="row" key={index}>
                                        {category && category.map((item, index) => {
                                            
                                                return (
                                                    <div className="col-md-3" key={index}>
                                                        <div className="insurance-contant-box-text">
                                                            <img src={IMAGE_URL + '/' + item.image} />
                                                            <h4>{item.name}</h4>
                                                            <Link href={'/insurance/form/'+item.canonical_name + '?category=' + item.name + '&id=' + item.id} className="hvr-sweep-to-right">Enquiry</Link>
                                                        </div>
                                                    </div>
                                                )
                                            
                                        })}
                                        
                                    </div>
                                );
                            
                        })} */}

                                {/* <div className="col-md-3">
                                    <div className="text-wapper-contnt">
                                    {category.length > 0 && category.map((category, index) => {

                                        if(index > 0 && index < 3){
                                            return (
                                                <div className="col" key={index}>
                                                    <div className="insurance-contant-box-text">
                                                        <Link to={'/insurance/form/'+category.canonical_name + '?category=' + category.name + '&id=' + category.id}><img src={IMAGE_URL + '/' + category.image} className="img-fluid" /></Link>
                                                        <Link to={'/insurance/form/'+category.canonical_name + '?category=' + category.name + '&id=' + category.id}><h4>{category.name}</h4></Link>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-wapper-contnt">
                                    {category.length > 2 && category.map((category, index) => {

                                        if(index > 2 && index < 5){
                                            return (
                                                <div className="col" key={index}>
                                                    <div className="insurance-contant-box-text">
                                                        <Link to={'/insurance/form/'+category.canonical_name + '?category=' + category.name + '&id=' + category.id}><img src={IMAGE_URL + '/' + category.image} className="img-fluid" /></Link>
                                                        <Link to={'/insurance/form/'+category.canonical_name + '?category=' + category.name + '&id=' + category.id}><h4>{category.name}</h4></Link>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                    </div>
                                </div> */}
                                {/* <div className="col-md-3">
                                    <div className="text-wapper-contnt">
                                    {category.length > 4 && category.map((category, index) => {

                                        if(index > 4 && index < 8){
                                            return (
                                                <div className="col" key={index}>
                                                    <div className="bike-insurance">
                                                        <Link to={'/insurance/form/'+category.canonical_name + '?category=' + category.name + '&id=' + category.id}><img src={IMAGE_URL + '/' + category.image} className="img-fluid" /></Link>
                                                        <Link to={'/insurance/form/'+category.canonical_name + '?category=' + category.name + '&id=' + category.id}><h4>{category.name}</h4></Link>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                        
                                    </div>
                                </div> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // <section className="insurance-types">
        //         <div className="container">
        //             {groups && groups.map((category, index) => {
        //                 return (
        //                     <div key={index} className="insurance-types-wrapper">
        //                         {category && category.map((item, index) => {
        //                             if(item.id === 1)
        //                                 return <InsuranceItem key={index} url={'/car-insurance'} image={IMAGE_URL + '/' + item.image} title={item.name} />
        //                             else if (item.id === 2)
        //                                 return <InsuranceItem key={index} url={'/insurance/form/'+item.canonical_name + '?category=' + item.name + '&id=' + item.id} image={IMAGE_URL + '/' + item.image} title={item.name} />
        //                             else
        //                                 return <InsuranceItem key={index} url={'/insurance/form/'+item.canonical_name + '?category=' + item.name + '&id=' + item.id} image={IMAGE_URL + '/' + item.image} title={item.name} />
        //                         })}
        //                     </div>
        //                 );
        //             })}
        //         </div>
        // </section>
    );
};

export default Insurance;