import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { IMAGE_URL } from '../../../CommonString';

const OurPartner = (props) => {

    const partner = props.partner;
    
    return (
        <div ref={props.reference}>
            <div className="container">
                <div className="our">
                    <h1>Our <span className="up">Partners</span></h1>
                    <p>At Beemabazaar we take pride in having enjoyed an extensive experience of<br />
                        aiding customers with their insurance requirements and have established strong<br />
                        partnerships with reputable insurers in the UAE market.
                    </p>
                </div>
            </div>
            <div className="container mb-5">
                {/* <div className="sild">
                    <div className="contain">
                        <OwlCarousel className="owl-carousel owl-theme" loop={true} margin={10} nav={true} 
                        responsive={{
                            // breakpoint from 0 up
                            0 : {
                                stagePadding: 0,
                                loop: false,
                                responsiveClass: true,
                                dots: false,
                                nav: true,
                                autoHeight: true,
                                items: 1
                            },
                            // breakpoint from 768 up
                            768 : {
                                items: 3
                            }
                        }} 
                        autoplay={true} autoplayTimeout={2000} dot={true} items={4}>
                            {partner && partner.map((partner, index) => {
                                return (
                                    <div className="item" key={index}>
                                        <img src={`${IMAGE_URL}/` + partner.image} alt={partner.name} style={{ width: '250px'}} />
                                    </div>
                                )
                            })}
                        </OwlCarousel>
                    </div>
                </div> */}
                <div className="row">
                {partner && partner.map((partner, index) => {
                    
                    return (
                        <div className="col-md-3 p-2 text-center" key={index}>
                            <div style={{boxShadow: '0px 0px 7px 1px #aaa8a8'}} className="p-5">
                                <img src={`${IMAGE_URL}/` + partner.image}  alt={partner.name} className="img-fluid" />
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
    );
};

export default OurPartner;