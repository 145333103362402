import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { PUBLIC_URL } from '../../../CommonString';
import ScrollToTopManual from '../../common/ScrollToTopManual';
import SpinnerComponent from '../../common/SpinnerComponent';
import Footer from '../../layout/english/Footer';
import Header from '../../layout/english/Header';

const ClaimAssistance = () => {

    const [policeReport, setPoliceReport] = useState({file: '', name: '', error: ''});
    const [mulkiya, setMulkiya] = useState({file: '', name: '', error: ''});
    const [licence, setLicence] = useState({file: '', name: '', error: ''});
    const [policeReportError, setPoliceReportError] = useState(false);
    const [mulkiyaError, setMulkiyaError] = useState(false);
    const [licenceError, setLicenceError] = useState(false);
    const [loading, setLoading] = useState(false);

    const ploiceChange = (e) => {
        
        setPoliceReport({file: e.target.files[0], name: e.target.files[0].name, error: ''});
        setPoliceReportError(false);
    }

    const mulkiyaChange = (e) => {
        setMulkiya({file: e.target.files[0], name: e.target.files[0].name, error: ''});
        setMulkiyaError(false);
    }
    const drivingLicenceChange = (e) => {
        setLicence({file: e.target.files[0], name: e.target.files[0].name, error: ''});
        setLicenceError(false);
    }

    const documenUpload = () => {

        if(policeReport.file !== '' && mulkiya.file !== '' && licence.file !== ''){

            let formData = new FormData();
            formData.append('police_report', policeReport.file);
            formData.append('mulkiya_copy', mulkiya.file);
            formData.append('driving_licence', licence.file);

            setLoading(true);

            axios({
                url: `${PUBLIC_URL}/claimeassistance/store`,
                method: 'POST',
                data: formData,
            }).then(response => {
                setLoading(false);
                if(response.data.status === 'success'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        html: `<p>${response.data.message}</p><p>Our executive will contact you!</p>`,
                    });

                    setPoliceReport({file: '', name: '', error: ''});
                    setMulkiya({file: '', name: '', error: ''});
                    setLicence({file: '', name: '', error: ''});
                }

                if(response.data.status === 'error'){
                    if(response.data.errors.police_report){
                        setPoliceReportError(true);
                        setPoliceReport({file: policeReport.file, name: policeReport.name, error: response.data.errors.police_report[0]});
                    }
                    if(response.data.errors.mulkiya_copy){
                        setMulkiyaError(true);
                        setMulkiya({file: mulkiya.file, name: mulkiya.name, error: response.data.errors.mulkiya_copy[0]});
                    }
                    if(response.data.errors.driving_licence){
                        setLicenceError(true);
                        setLicence({file: licence.file, name: licence.name, error: response.data.errors.driving_licence[0]});
                    }
                }

            }).catch((error) => {
                setLoading(false);
            });
        }
        else{
            if(policeReport.file === ''){
                setPoliceReportError(true);
                setPoliceReport({file: policeReport.file, name: policeReport.name, error: 'Police report is required'});
            }
            if(mulkiya.file === ''){
                setMulkiyaError(true);
                setMulkiya({file: mulkiya.file, name: mulkiya.name, error: 'Mulkiya copy is required'});
            }
            if(licence.file === ''){
                setLicenceError(true);
                setLicence({file: licence.file, name: licence.name, error: 'Driving licence is required'});
            }
        }
    }

    useEffect(() => {

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {

            window.scrollTo({ 
                behavior: 'smooth',
                top: 0,
                left: 0 
            });
        }
    });

    const onNothingClick = (e) => {

        e.preventDefault();
    }

    return (
        <>
            <Header />
            <SpinnerComponent open={loading} />
                <section className="claim-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="save assistance">
                                    <div className="proess">
                                        <div className="select">
                                            <h4>Upload Claim Details</h4>
                                            <div className="form-group">
                                                <label>Police Report</label>
                                                <div className="custom-file">
                                                    <input type="file" name="police_report" onChange={ploiceChange} className="custom-file-input form-control" id="customFile" />
                                                    <label className="custom-file-label" for="customFile">{policeReport.name ? policeReport.name : 'Choose file'}</label>
                                                </div>
                                                {policeReportError ? <label style={{color: 'red'}}>{policeReport.error}</label> : ''}
                                            </div>
                                            <div className="form-group">
                                                <label>Mulkiya Copy</label>
                                                <div className="custom-file">
                                                    <input type="file" name="mulkiya_copy" onChange={mulkiyaChange} className="custom-file-input form-control" id="customFile" />
                                                    <label className="custom-file-label" for="customFile">{mulkiya.name ? mulkiya.name : 'Choose file'}</label>
                                                </div>
                                                {mulkiyaError ? <label style={{color: 'red'}}>{mulkiya.error}</label> : ''}
                                            </div>
                                            <div className="form-group">
                                                <label>Driving Licence</label>
                                                <div className="custom-file">
                                                    <input type="file" name="driving_licence" onChange={drivingLicenceChange} className="custom-file-input form-control" id="customFile" />
                                                    <label className="custom-file-label" for="customFile">{licence.name ? licence.name : 'Choose file'}</label>
                                                </div>
                                                {licenceError ? <label style={{color: 'red'}}>{licence.error}</label> : ''}
                                            </div>
                                            <a href onClick={onNothingClick} className="text-center" onClick={documenUpload} style={{padding: '10px 46px'}}>Upload</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />

            <ScrollToTopManual />
        </>
    );
};

export default ClaimAssistance;