import axios from 'axios';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { PUBLIC_URL } from '../../../CommonString';
import SpinnerComponent from '../../common/SpinnerComponent';

const ComplaintForm = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [file, setFile] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [fileName, setFileName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [loading, setLoading] = useState(false);

    const inputChange = (e) => {

        if(e.target.name === 'name'){
            setName(e.target.value);
            if(e.target.value === ''){
                setNameError(true);
            }
            else{
                
                setNameError(false);
            }
        }
        if(e.target.name === 'email'){
            setEmail(e.target.value);
            
            if(e.target.value === ''){
                setEmailError('Email field is required');
            }
            else{
                if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(e.target.value)){

                    setEmailError(false);
                }
                else{
                    setEmailError('Must be an Email');
                }
            }
        }
        if(e.target.name === 'mobile'){
            
            if(e.target.value.length <= 9){

                setMobile(e.target.value);
                if(e.target.value === ''){
                    setMobileError('Mobile field is required');
                }
                else{
                    if(isNaN(+e.target.value) == true){
                        setMobileError('Enter a valid mobile');
                    }
                    else if(e.target.value[0] == 0){
                        setMobileError('Avoid 0 at the begining');
                    }
                    else{
                        setMobileError(false);
                    }
                }
            }
        }
        if(e.target.name === 'file'){

            let file = e.target.files[0];
            
            setFileName(file.name);

            setFile(file);
        }
        if(e.target.name === 'message'){
            setMessage(e.target.value);
            if(e.target.value === ''){
                setMessageError(true);
            }
            else{
                
                setMessageError(false);
            }
        }
        if(e.target.name === 'subject'){
            setSubject(e.target.value);
            if(e.target.value === ''){
                setSubjectError(true);
            }
            else{
              
                
                setSubjectError(false);
            }
        }
    }

    const handleSubmit = () => {

        if(name !== '' && email !== '' && subject !== '' && mobile !== '' && message !== ''){

            if(mobile[0] == 0 && !/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
                setMobileError('Avoid 0 at the begining');
                setEmailError('Enter a valid email');
            }
            else if(isNaN(+mobile) == true && !/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
                setMobileError('Enter a valid mobile');
                setEmailError('Enter a valid email');
            }
            else if(mobile[0] == 0){
                setMobileError('Avoid 0 at the begining');
            }
            else if(isNaN(+mobile) == true){
                setMobileError('Enter a valid mobile');
            }
            else if(!/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
                setEmailError('Enter a valid email');
            }
            else{

                let formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('mobile', mobile);
                formData.append('subject', subject);
                formData.append('message', message);
                formData.append('file', file ? file : null);
                
                setLoading(true);

                axios({
                    url: `${PUBLIC_URL}/complaint/store`,
                    method: 'POST',
                    data: formData,

                }).then(response => {
                    setLoading(false);
                    if(response.data.status === 'success'){
                        
                        setName('');
                        setEmail('');
                        setMobile('');
                        setSubject('');
                        setFileName('');
                        setFile('');
                        setMessage('');
                        
                        Swal.fire({
                            title: '<strong>Thank You</strong>',
                            icon: 'success',
                            html:
                            'Our executive will review your complaint.',
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonText:
                            '<i class="fa fa-thumbs-up"></i> OK!',
                            confirmButtonAriaLabel: 'Thumbs up, great!',
                        });
                    }

                }).catch((error) => {
                    setLoading(false);
                });
            }
        }
        else{
            if(name === ''){
                setNameError(true);
            }
            else{
                setNameError(false);
            }
            if(email === ''){
                setEmailError(true);
                setEmailError('Email field is required');
            }
            else{
                if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
                    setEmailError(false);
                }
                else{
                    setEmailError('Must be an email');
                }
            }
            if(mobile === ''){
                setMobileError('Mobile field is required');
            }
            else{
                if(mobile[0] == 0){
                    setMobileError('Avoid 0 at the begining');
                }
                else if(isNaN(+mobile) == true){
                    setMobileError('Enter a valid mobile');
                }
                else{
                    setMobileError(false);
                }
            }
            if(subject === ''){
                setSubjectError(true);
            }
            else{
                setSubjectError(false);
            }
            if(message === ''){
                setMessageError(true);
            }
            else{
                setMessageError(false);
            }
        }
    }

    const extension = {
        position: 'relative',
        top: '37px',
        left: '5px',
        width: '9%',
        textAlign: 'center',
    }



    return (
        <div>
            <SpinnerComponent open={loading} />
            <div className="continer">
                <div className="complints-form">
                    {/* <!-- START CONTACT FORM --> */}
                    <section className="section-padding">
		                <div className="container"  >
			                <div className="row">
			                    <div className="col-lg-12 text-center">
					                <div className="section-title">
                                        <h3>Complaints Form</h3>
                                        <hr />
                                        {/* <span className="line"></span> */}
                                        {/* <!--<i className="icofont icofont-map-pins"></i> */}
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm.</p>--> */}
					                </div>
				                </div>
                                {/* <!-- end section title --> */}
                                <div className="mx-auto col-lg-10 mt-40"> 
                                    <div className="contact">
                                        <form  className="complaint-form" name="complaintForm" id="complaintForm" method="POST" enctype="multipart/form-data">
                                            <div className="row">
                                                <font color="#FF0000" style={{fontSize: '12px', textAlign: 'center', width: '100%'}}></font>         
                                                <div className="col-lg-6">
                                                    <div className="form-group col-lg-12">
                                                        <input type="text" name="name" onChange={inputChange} value={name} className="form-control" id="name" placeholder="Name" required="required" />
                                                        {nameError ? <div className="text-danger">Name Field is required</div> : ''}
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                        <input type="email" name="email" onChange={inputChange} className="form-control" id="email" placeholder="Email" required="required" />
                                                        {emailError ? <div className="text-danger">{emailError}</div> : ''}
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                        <div style={extension}>+971</div>
                                                        <input type="tel" name="mobile" style={{paddingLeft: '45px'}} onChange={inputChange} max={9} className="form-control mobile" id="mobile" placeholder="Mobile" required="required" pattern="[0-9]{10,12}" title="Mobile number should only contain numbers" />
                                                        {mobileError ? <div className="text-danger">{mobileError}</div> : ''}
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                        <input type="file" name="file" onChange={inputChange} className="form-control" id="fileatt"  required="required" />
                                                    </div>
								                </div>
								                <div className="col-lg-6">
                                                    <div className="form-group col-lg-12">
                                                        <input type="text" name="subject" onChange={inputChange} className="form-control" id="subject" placeholder="Subject" required="required" />
                                                        {subjectError ? <div className="text-danger">Subject Field is required</div> : ''}
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                        <textarea rows="5" name="message" onChange={inputChange} className="form-control" id="message" placeholder="Your Message" required="required"></textarea>
                                                        {messageError ? <div className="text-danger">Subject Field is required</div> : ''}
                                                    </div>
									                {/* <div className="form-group col-lg-12">
                                                        <div className="g-recaptcha" data-sitekey="6LeEsAkbAAAAAPyTAzy2szTlSoVDTjrBxo7wrA9w"></div>
                                                           <div className="captcha-holder" style="margin-left:-15px;"></div>
                                                    </div>
									                <div className="col-lg-12 form-group error-message" id="captchaMsg" style={{display: 'block'}}>
                                                        Please confirm captcha to proceed
                                                    </div> */}
									                <div className="form-group col-lg-12 mb0">
                                                        <button onClick={handleSubmit} type="button" className="sumit">Register</button>
									                </div>
								                </div>
                                            </div>
                                        </form>
                                    </div>
				                </div>
                            </div>
		                </div>
	                </section>	
                </div>
            </div>
        </div>
    );
};

export default ComplaintForm;