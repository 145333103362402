import React from 'react';
import NavContent from './NavContent';

const Header = () => {
    return (
        <header className="smaller">
            <NavContent />
        </header>
    );
};

export default Header;