import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    return (
        <div>
            {/* <div className="float">
                <a className="my-float" href="https://api.whatsapp.com/send?phone=+97143024555"><img src="/images/whatsapp-logo.png" /></a>
            </div> */}
            <footer className="coppy top-0">
                <div className="container">
                <div className="row">
                    <div className="col-md-3">
                    <div className="general">
                        <img src="images/footer_03.png" />
                        <div className="car">
                            <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                            <h6>Motor Insurance</h6>
                        </div>
                        <hr />
                        <div className="insurance">
                            <ul>
                                <li><Link to="/car-insurance">Individual Car Insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Motor Fleet Insurance">Motor Fleet Insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Bike insurance">Bike insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Commercial Vehicle Insurance">Commercial Vehicle Insurance</Link></li>
                            </ul>
                        </div>
                        {/* <div className="insurance general-insurance">
                            <h6 style={{color: '#FFF', paddingLeft: '40px'}}><i className="fa fa-hand-o-right" aria-hidden="true"></i> Motor Insurance</h6>
                            <ul>
                                <li><Link to="/car-insurance">Individual Car Insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Motor Fleet Insurance">Motor Fleet Insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Bike insurance">Bike insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Commercial Vehicle Insurance">Commercial Vehicle Insurance</Link></li>
                            </ul>
                            
                        </div> */}
                    </div>
                    </div>
                    <div className="col-md-3">
                    <div className="car" style={{marginTop: '135px'}}>
                        <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                        <h6>Life Insurance</h6>
                    </div>
                    <hr />
                    <div className="insurance">
                        <ul>
                        <li><Link to="/generalinsurance?category=Term Insurance">Term Insurance</Link></li>
                        <li><Link to="/generalinsurance?category=Key man Insurance">Key man Insurance</Link></li>
                        <li><Link to="/generalinsurance?category=Whole of Life Plan">Whole of Life Plan</Link></li>
                        <li><Link to="/generalinsurance?category=Critical Illness Plan ">Critical Illness Plan</Link></li>
                        <li><Link to="/generalinsurance?category=Health cover abroad ">Health cover abroad</Link></li>
                        </ul>
                    </div>
                    {/* <div className="car" style={{marginTop: '70px'}}>
                        <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                        <h6>Other Insurance</h6>
                    </div>
                    <hr />
                    <div className="insurance">
                        <ul>
                            <li><Link to="/generalinsurance?category=Group Health Insurance">Group Health Insurance</Link></li>
                            <li><Link to="/generalinsurance?category=Other General Insurance Products">Other General Insurance Products</Link></li>
                            <li><Link to="/generalinsurance?category=Cancer Insurance">Cancer Insurance</Link></li>
                            <li><Link to="/generalinsurance?category=Home Insurance">Home Insurance</Link></li>
                        </ul>
                    </div> */}
                    </div>
                    <div className="col-md-3">
                    <div className="car" style={{marginTop: '135px'}}>
                        <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                        <h6>Investment</h6>
                    </div>
                    <hr />
                    <div className="insurance">
                        <ul>
                        <li><Link to="/generalinsurance?category=Child Education Plan">Child Education Plan</Link></li>
                        <li><Link to="/generalinsurance?category=Pension plan">Pension plan</Link></li>
                        <li><Link to="/generalinsurance?category=Guaranteed Return Plan">Guaranteed Return Plan</Link></li>
                        <li><Link to="/generalinsurance?category=Money Back Plan">Money Back Plan</Link></li>
                        <li><Link to="/generalinsurance?category=Retirement Planning">Retirement Planning</Link></li>
                        <li><Link to="/generalinsurance?category=Wealth Management">Wealth Management</Link></li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-md-3">
                        <div className="car" style={{marginTop: '135px'}}>
                            <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                            <h6>Health Insurance</h6>
                        </div>
                        <hr />
                        <div className="insurance">
                            <ul>
                            <li><Link to="/generalinsurance?category=Individual Insurance">Individual Insurance</Link></li>
                            <li><Link to="/generalinsurance?category=Group Insurance">Group Insurance</Link></li>
                            </ul>
                        </div>
                        <div className="car" style={{marginTop: '20px'}}>
                            <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                            <h6>Fire &amp; General Accident</h6>
                        </div>
                        <hr />
                        <div className="insurance">
                            <ul>
                                <li><Link to="/generalinsurance?category=Property Insurance">Property Insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Liability Insurance">Liability Insurance</Link></li>
                                <li><Link to="/generalinsurance?category=Marine Insurance ">Marine Insurance </Link></li>
                                {/* <li><Link to="/generalinsurance?category=Employee Benefits">Employee Benefits</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </footer>
            <section className="footer">
                <div className="container">
                    <div className="text-white text-center">
                        Copyright &copy; {year} Beemabazaar All rights reserved. Developed by <a href="https://prompttechsolutions.com/" target="_blank" className="text-white">PromptTech</a> 
                    </div>
                    {/* <div className="home">
                        <a href="">Home </a>
                        <a href="">About Us</a>
                        <a href="">Careers </a>
                        <a href="">Legal &amp; Admin Policies</a>
                        <a href="">ISNP</a>
                        <a href="">Insurance Companies</a>
                        <a href="">Articles</a>
                        <a href="">Contact Us</a>
                        <a href="">Know your advisor</a>
                        <a href="">Newsroom</a>
                        <a href=""> <i className="fa fa-facebook-official" aria-hidden="true"></i></a>
                        <a href=""> <i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                        <a href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                    </div> */}
                </div>
            </section>
        </div>
    );
};

export default Footer;