import React from 'react';

const Procedure = () => {
    return (
        <div className="container">
            <div className="complaint-wrapper">
                <h2>Complaint Handling Procedure</h2>
                <hr />
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://app.lucidchart.com/documents/embeddedchart/4c83fb1e-474f-47bb-8fc7-90be97fa17ac" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    );
};

export default Procedure;