import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OtpInput, { ResendOTP } from 'otp-input-react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { PUBLIC_URL } from '../../../CommonString';
import SpinnerComponent from '../../common/SpinnerComponent';
import Footer from '../../layout/english/Footer';
import Header from '../../layout/english/Header';

const GeneralInsurance = (props) => {

    let categoryName = ((new URLSearchParams(props.location.search).get('category')) != '') ? (new URLSearchParams(props.location.search).get('category')) : '';
    const [page, setPage] = useState(1);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');
    const [dob, setDob] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [nationalityId, setNationalityId] = useState('');
    const [categoryId, setCategory] = useState(0);
    const [ipAddress, setIpAddress] = useState(0);
    const history = useHistory();
    
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [dobError, setDobError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [nationalityError, setNationalityError] = useState('');
    const [loading, setLoading] = useState(false);

    const [OTP, setOTP] = useState("");
    const [OTPError, setOTPError] = useState("");
    const [verifiedOtp, setVerifiedOtp] = useState('');
    const [disableButton, setDisableButton] = useState(false);


    const inputChange = (e) => {
        
        if(e.target.name === 'name'){

            setName(e.target.value);

            if(e.target.value !== ''){

                // let newDob = year + '/' + month + '/' + day;
                // let date = new Date(newDob);
                // let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
                // setDob(currectDob);
            }
            else{

                setNameError('Name field is requird');
            }
  
        }

        if(e.target.name === 'email'){

            setEmail(e.target.value);

            if(e.target.value !== ''){

                if(!/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(e.target.value)){
                    setEmailError('Must be an email');
                }
                else{
                    setEmailError('');
                }
                
                // let newDob = year + '/' + month + '/' + day;
                // let date = new Date(newDob);
                // let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
                // setDob(currectDob);
                
            }
            else{
                setEmailError('Email field is required');
            }
            
        }

        if(e.target.name === 'mobile'){

            if(e.target.value.length <= 9){

                setMobile(e.target.value);

                if(e.target.value !== ''){
                    
                    if(e.target.value[0] == 0){
                        setMobileError('Avoid 0 at the begining');
                    }
                    else if(isNaN(+e.target.value) == true){
                        setMobileError('Enter a valid mobile');
                    }
                    else{
                        setMobileError('');
                    }
                }
                else{
                    setMobileError('Mobile field is required');
                }
            }
            
        }

        // if(e.target.name === 'day'){

        //     if(e.target.value !== '' && e.target.value >= 0 && e.target.value <= 31){
        //         setDobError();
        //         setDay(e.target.value);
        
        //         let newDob = year + '/' + month + '/' + e.target.value;
        //         let date = new Date(newDob);
        //         let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
        //         setDob(currectDob);
        //     }
        //     else{
        //         setDobError('Date of birth field is required');
        //     }
        // }

        // if(e.target.name === 'month' && e.target.value >= 0 && e.target.value <= 12){
        //     setDobError();
        //     if(e.target.value !== ''){
        //         setMonth(e.target.value);
        
        //         let newDob = year + '/' + e.target.value + '/' + day;
        //         let date = new Date(newDob);
        //         let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
        //         setDob(currectDob);
        //     }
        //     else{

        //         setDobError('Date of birth field is required');
        //     }
        // }

        // if(e.target.name === 'year'){

        //     let thisYear = new Date();

        //     let yearBack = new Date(thisYear.getFullYear());
        //     console.log(yearBack.getFullYear());

        //     if(e.target.value !== '' && e.target.value){
        //         setDobError();
        //         setYear(e.target.value);
        
        //         let newDob = e.target.value + '/' + month + '/' + day;
        //         let date = new Date(newDob);
        //         let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
        //         setDob(currectDob);
        //     }
        //     else{
        //         setDobError('Date of birth field is required');
        //     }
        // }

        if(e.target.name === 'dob'){

            if(e.target.value !== '' && e.target.value){
                setDobError();
                // let date = new Date(e.target.value);
                // let dob = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                
                setDob(e.target.value);
            }
            else{
                setDobError('Date of birth field is required');
            }
        }

        if(e.target.name === 'gender'){
            setGender(e.target.value);
            if(e.target.value !== ''){
        
                // let newDob = year + '/' + month + '/' + day;
                // let date = new Date(newDob);
                // let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
                // setDob(currectDob);
            }
            else{
                setGenderError('Gender field is required');
            }
        }

        if(e.target.name === 'nationality'){

            if(e.target.value !== ''){

                setNationalityId(e.target.value);
                setNationality('');
        
                // let newDob = year + '/' + month + '/' + day;
                // let date = new Date(newDob);
                // let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();
        
                // setDob(currectDob);
            }
            else{

                setNationalityError('Nationality field is required');
            }
        }
      }

    useEffect(() => {
        
        if(firstLoad == true){
            
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            setLoading(true);
            axios({
                url: `${PUBLIC_URL}/country/list`,
                method: 'POST',
            }).then(response => {
                setLoading(false);
                if(response.data.status === 'success'){
                    setCountryList(response.data.data);
                }
    
            }).catch((error) => {
                setLoading(false);
            });
        }
        
        setFirstLoad(false);
  
    });
    
    const handleSubmit = () => {

        if(name !== '' && email !== '' && mobile !== ''){

            if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
                if(page === 1){

                    let otpData = new FormData();
                    otpData.append('mobile', mobile);

                    setLoading(true);

                    axios({
                    url: `${PUBLIC_URL}/generate/otp`,
                    method: 'POST',
                    data: otpData,

                    }).then(response => {

                        setLoading(false);

                    }).catch((error) => {});
                    
                    setPage(2);

                }
                else{

                    let verifyData = new FormData();
                    let verified_otp = '';

                    verifyData.append('mobile', mobile);
                    verifyData.append('otp', OTP);

                    setLoading(true);
                    setDisableButton(true);

                    axios({
                        url: `${PUBLIC_URL}/verify/otp`,
                        method: 'POST',
                        data: verifyData,

                    }).then(response => {

                        if(response.data.status === 'success'){

                            setVerifiedOtp(response.data.otp);
                            verified_otp = response.data.otp;
                            
                            if (OTP == verified_otp) {

                                setLoading(true);
                                setOTPError('');

                                let formData = new FormData();
                                formData.append('category', categoryId);
                                formData.append('ip_address', ipAddress);
                                formData.append('name', name ? name : null);
                                formData.append('email', email ? email : null);
                                formData.append('phone', mobile ? mobile : null);
                                formData.append('gender', gender ? gender : null);
                                formData.append('nationality', nationalityId ? nationalityId : null);
                                formData.append('date_of_berth', dob ? dob : null);
                                formData.append('category_name', categoryName ? categoryName : null);
                                
                                axios({
                                    url: `${PUBLIC_URL}/enquiry/store`,
                                    method: 'POST',
                                    data: formData,
                                }).then(response => {
                                    setLoading(false);
                                    if(response.data.status === 'success'){
                                    
                                        Swal.fire({
                                            title: '<strong>Thank You</strong>',
                                            icon: 'success',
                                            html:
                                                'One of our Member is working on your custom quote and will be in touch shortly.',
                                            showCloseButton: true,
                                            showCancelButton: false,
                                            focusConfirm: false,
                                            confirmButtonText:
                                                '<i class="fa fa-thumbs-up"></i> Go to home page!',
                                            confirmButtonAriaLabel: 'Thumbs up, great!',
                                        
                                        }).then((result) => {
                                            history.push('/');
                                        });
                                    }
                                    else{
                                        Swal.fire({
                                            title: 'error!',
                                            text: response.data.message,
                                            icon: 'error',
                                        });
                                    }
                        
                                }).catch((error) => {
                                    setLoading(false);
                                    Swal.fire({
                                        title: 'Error!',
                                        text: 'Something went wrong',
                                        icon: 'error',
                                    });
                                    
                                });
                            }
                            
                        }
                        else{
                            setLoading(false);
                            setDisableButton(false);
                            setOTPError(response.data.message);
                        }
                        
                    }).catch((error) => {});
                }
            }
            else{
                setEmailError('Enter a valid email');
            }
        }
        else{
            if(name === ''){
                
                setNameError('Name field is required');
            }
            else{
                setNameError('');
            }
            if(email === ''){
                setEmailError('Email field is required');
            }
            else{
                if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
                    setEmailError('');
                }
                else{
                    setEmailError('Enter a valid email');
                }
            }
            if(mobile === ''){
                setMobileError('Mobile field is required');
            }
            else{
                if(mobile[0] == 0){
                    setMobileError('Avoid 0 at the begining');
                }
                else if(isNaN(+mobile) == true){
                    setMobileError('Enter a valid mobile');
                }
                else{
                    setMobileError('');
                }
            }
            // if(dob === ''){
            //     setDobError('Date of birth is required');
            // }
            // if(gender === ''){
            //     setGenderError('Gender is required');
            // }
            // if(nationalityId === ''){

            //     setNationalityError('Nationality is required');
            // }
        }
    }

    const extension = {
        position: 'relative',
        top: '30px',
        left: '5px',
        backgroundColor: '#d4d4fa',
        width: '9%',
        textAlign: 'center',
    }

    const mystyle = {
        width: "50px",
        height: "50px",
        backgroundColor: "lighten(#0f0f1a, 5%)",
        // border: "none",
        lineHeight: "50px",
        textAlign: "center",
        fontSize: "24px",
        display: "initial",
        fontWeight: "200",
        color: "#172f3b",
        margin: "0 2px",
    };

    const sendOtp = () => {

        let otpData = new FormData();
        otpData.append('mobile', mobile);
        
        setLoading(true);

        axios({
          url: `${PUBLIC_URL}/generate/otp`,
          method: 'POST',
          data: otpData,
    
        }).then(response => {

            setLoading(false);

        }).catch((error) => {
            setLoading(false);
        });
        
      }

    const renderButton = (buttonProps) => {
        return (
            <button {...buttonProps}>
                {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : <a onClick={sendOtp}>Resend</a>}
            </button>
        );
    }

    const pageDown = () => {
        
        setPage(page - 1);
    }

    const renderTime = () => React.Fragment;

    return (
        <>
            <Header />
            <SpinnerComponent open={loading} />
            <section className="other-insurance-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="steps-form">
                                <div className="form-wizard">
                                    <div className="steps">
                                    </div>
                                    <div className="myContainer" style={{minWidth: '30vw', minHeight: '60vh'}}>
                                        {page === 1 ?
                                            <div className="form-container animated active flipInX">
                                                <h2 className="text-center form-title">Customer Details</h2>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" onChange={inputChange} value={name} name="name" placeholder="Customer name" />
                                                    {nameError ? <div className="text-danger">{nameError}</div> : ''}
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" className="form-control" onChange={inputChange} value={email} name="email" placeholder="Email" />
                                                    {emailError ? <div className="text-danger">{emailError}</div> : ''}
                                                </div>
                                                <div className="form-group">
                                                    <div style={extension}>+971</div>
                                                    <input type="mobile" className="form-control mobile" onChange={inputChange} value={mobile} name="mobile" placeholder="Mobile" />
                                                    {/* <small className="text-danger">Warning : Enter 9 digits of mobile number and avoid 0 at the begining</small> */}
                                                    {mobileError ? <div className="text-danger">{mobileError}</div> : ''}
                                                </div>
                                                {/* <div className="form-group">
                                                    <label>Date Of Birth</label>
                                                    <input type="date" className="form-control" onChange={inputChange} value={dob} name="dob" placeholder="DOB" />
                                                    {dobError ? <div className="text-danger">{dobError}</div> : ''}
                                                </div> */}
                                                
                                                {/* <fieldset className="p-4">
                                                    <div>
                                                    <legend className="font-bold">Gender</legend>
                                                    </div>
                                                    <div className="inline-flex">
                                                    {gender === 'male' ? <input className="hidden mr-1" checked onClick={inputChange} type="radio" id="male" value="male" name="gender"/>
                                                    : <input className="hidden mr-1" onClick={inputChange} type="radio" id="male" value="male" name="gender"/> }
                                                    <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-l" for="male">Male</label>
                                                    {gender === 'female' ? <input className="hidden mr-1 ml-2" checked onClick={inputChange} type="radio" id="female" value="female" name="gender" />
                                                    : <input className="hidden mr-1 ml-2" onClick={inputChange} type="radio" id="female" value="female" name="gender" />}
                                                    <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer" for="female">Female</label>
                                                    {gender === 'other' ? <input className="hidden mr-1 ml-2" checked onClick={inputChange} type="radio" id="non-binary" value="non-binary" name="gender" />
                                                    : <input className="hidden mr-1 ml-2" onClick={inputChange} type="radio" id="non-binary" value="other" name="gender" />}
                                                    <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-r" for="non-binary">Others</label>
                                                    </div>
                                                    {genderError ? <div className="text-danger">{genderError}</div> : ''}
                                                </fieldset> */}
                                                {/* <select name="format" name="nationality" id="format" onChange={inputChange} style={{marginBottom: '20px'}}>
                                                    <option>Nationality</option>
                                                    {countryList && countryList.map((list, index) => {
                                                    if(list.name === nationalityId)
                                                        return <option selected key={index} value={list.id}>{list.name}</option>
                                                    else
                                                        return <option key={index} value={list.id}>{list.name}</option>
                                                    })}
                                                </select> */}
                                                {/* {nationalityError ? <div className="text-danger">{nationalityError}</div> : ''} */}
                                                <div className="form-group text-center mar-b-0 ">
                                                    <input type="submit" onClick={handleSubmit}  value="SUBMIT" className="btn btn-primary submit" />
                                                </div>
                                            </div>
                                        : page === 2 ? 
                                            <div className="form-container animated active flipInX">
                                                <h2 className="text-center form-title">{categoryName}</h2>
                                                <OtpInput
                                                    value={OTP}
                                                    onChange={setOTP}
                                                    autoFocus OTPLength={6}
                                                    otpType="number"
                                                    disabled={false}
                                                    inputStyles={mystyle}
                                                />
                                                
                                                <ResendOTP renderButton={renderButton} renderTime={renderTime} />
                                                {OTPError ? <div className="text-danger">{OTPError}</div> : ''}
                                                <div className="form-group text-center mar-b-0 ">
                                                    <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />
                                                    {disableButton == false ?<input type="button" onClick={handleSubmit} value="Submit OTP" className="btn btn-primary next" />:
                                                    <input type="button" value="Loading..." className="btn btn-primary next disabled" />}
                                                </div>
                                            </div>
                                        : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default GeneralInsurance;