import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { PUBLIC_URL } from '../../../CommonString';
import ScrollToTopManual from '../../common/ScrollToTopManual';
import SpinnerComponent from '../../common/SpinnerComponent';
import Footer from './Footer';
import Header from './Header';

const ContactUs = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [commentError, setCommentError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [emailErrorText, setEmailErrorText] = useState('');
    const [phoneErrorText, setPhoneErrorText] = useState('');
    const [commentErrorText, setCommentErrorText] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handlChange = e => {
        if(e.target.name === 'name'){

            if(e.target.value !== ''){
                setNameError(false);
                setName(e.target.value);
            }
            else{
                setNameErrorText('Name field is required')
                setNameError(true);
            }
        }
        else if(e.target.name === 'email'){
            if(e.target.value !== ''){
                if(e.target.value.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)){
                    setEmailError(false);
                    setEmail(e.target.value);
                }
                else{
                    setEmailErrorText('Must be an email');
                    setEmailError(true);
                }
            }
            else{
                setEmailErrorText('Email field is required');
                setEmailError(true);
            }
        }
        else if(e.target.name === 'phone'){
            if(e.target.value !== ''){
                setPhoneError(false);
                setPhone(e.target.value);
            }
            else{
                setPhoneErrorText('Phone field is required');
                setPhoneError(true);
            }
        }
        else if(e.target.name === 'comment'){
            if(e.target.value !== ''){
                setCommentError(false);
                setComment(e.target.value);
            }
            else{
                setCommentErrorText('Comment field is required');
                setCommentError(true);
            }
        }
    }

    const handlSubmit = () => {

        if(name !== '' && email !== '' && phone !== '' && comment !== ''){

            if(email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)){
                
                let formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('phone', phone);
                formData.append('comment', comment);

                setLoading(true);

                axios({
                    url: `${PUBLIC_URL}/contactus/store`,
                    method: 'POST',
                    data: formData,
                }).then(response => {
                    
                    setLoading(false);

                    if(response.data.status === 'success'){
                        setName('');
                        setEmail('');
                        setPhone('');
                        setComment('');
                        
                        Swal.fire({
                            title: '<strong>Thank You</strong>',
                            icon: 'success',
                            html: response.data.message,
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonText:
                                '<i class="fa fa-thumbs-up"></i> Go to home page!',
                            confirmButtonAriaLabel: 'Thumbs up, great!',
                        
                        }).then((result) => {
                            history.push('/');
                        });

                        // Swal.fire({
                        //     icon: 'success',
                        //     title: 'Success!',
                        //     text: response.data.message,
                        // });
                    }
                    else{
                        console.log(response.data.errors);
                        if(response.data.errors.phone){
                            setPhoneError(true);
                            setPhoneErrorText(response.data.errors.phone[0]);
                        }

                        if(response.data.errors.comment){
                            setCommentError(true);
                            setCommentErrorText(response.data.errors.comment[0]);
                        }

                        if(response.data.errors.email){
                            setEmailErrorText(true);
                            setEmailErrorText(response.data.errors.email[0]);
                        }

                        if(response.data.errors.name){
                            setNameError(true);
                            setNameErrorText(response.data.errors.name[0]);
                        }
                    }

                }).catch((error) => {
                    setLoading(false);

                    // if(error.response.data.status === 'error'){
                    //     if(error.response.data.error.phone){
                    //         setPhoneError(true);
                    //         setPhoneErrorText(error.response.data.error.phone[0]);
                    //     }

                    //     if(error.response.data.error.comment){
                    //         setCommentError(true);
                    //         setCommentErrorText(error.response.data.error.comment[0]);
                    //     }

                    //     if(error.response.data.error.email){
                    //         setEmailErrorText(true);
                    //         setEmailErrorText(error.response.data.error.email[0]);
                    //     }

                    //     if(error.response.data.error.name){
                    //         setNameError(true);
                    //         setNameErrorText(error.response.data.error.name[0]);
                    //     }
                    // }
                });
            }
            else{
                setEmailErrorText('Must be an email');
                setEmailError(true);
            }
        }
        else{
            if(name === ''){
                setNameError(true);
                setNameErrorText('Name field is required')
            }
            if(email === ''){
                setEmailError(true);
                setEmailErrorText('Email field is required');
            }
            if(phone === ''){
                setPhoneError(true);
                setPhoneErrorText('Phone field is required');
            }
            if(comment === ''){
                setCommentError(true);
                setCommentErrorText('Comment field is required')
            }
        }
    }

    useEffect(() => {

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {

            window.scrollTo({ 
                behavior: 'smooth',
                top: 0,
                left: 0 
            });
        }
    });

    const onNothingClick = (e) => {

        e.preventDefault();
    }

    return (
        <>
            <Header />
            <SpinnerComponent open={loading} />
            <section className="contact-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-from">
                                <div className="contact-text">
                                    <h4>Contact</h4>
                                    <div className="form-group">
                                        <input type="name" className="form-control" name="name" onChange={handlChange} placeholder="Enter Name" />
                                        {nameError ? <div style={{color: 'red'}}>{nameErrorText}</div> : ''}
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" onChange={handlChange} placeholder="Enter Email" />
                                        {emailError ? <div style={{color: 'red'}}>{emailErrorText}</div> : ''}
                                    </div>
                                    <div className="form-group">
                                        <input type="phone" className="form-control" name="phone" onChange={handlChange} placeholder="Enter Phone Number" />
                                        {phoneError ? <div style={{color: 'red'}}>{phoneErrorText}</div> : ''}
                                    </div>
                                    <div className="form-group">
                                        <div className="contact-bottum">
                                            <textarea className="form-control status-box" name="comment" onChange={handlChange} rows="3" placeholder="Enter your comment here..."></textarea>
                                            {commentError ? <div style={{color: 'red'}}>{commentErrorText}</div> : ''}
                                        </div>
                                    </div>
                                    <a href onClick={onNothingClick} onClick={handlSubmit} style={{color: '#FFF', cursor: 'pointer'}}>Submit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="map">
                <div className="row container-fluid pl-4 pr-0">
                <div className="col-md-6 pt-4 pl-2 pr-2 pb-3 text-center">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7217.682284562577!2d55.297015!3d25.242275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc6d84ae1d194e61f!2sOmega+Insurance+Brokers+LLC!5e0!3m2!1sen!2sin!4v1521603580108" width="100%" height="600px"></iframe>
                </div>
                <div className="col-md-6 contact-address">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 pl-5 text-white address-head-office">
                                <h4 className="address-heading">Head office</h4>
                                <hr />
                                <div className="mt-5">
                                    <p>OMEGA INSURANCE BROKERS LLC </p>
                                    <p>206, Crystal Business Center </p>
                                    <p>Trade Center Road, </p>
                                    <p>Near ADCB Metro Station, </p>
                                    <p>P.O. Box 49474, </p>
                                    <p>Dubai, U.A.E </p>
                                </div>
                            </div>
                            <div className="col-md-6 pl-5 mb-4 text-white address-head-office">
                                <h4 className="address-heading">Connect with us</h4>
                                <hr />
                                <div className="mt-5">
                                    <p><i className="fa fa-envelope"></i> ome@omegainsurance.ae </p>
                                    <p><i className="fa fa-envelope"></i> complaints@omegainsurance.ae </p>
                                    <p><i className="fa fa-phone"></i> Land Line : +971 (0)4 302 45 55 </p>
                                    <p><i className="fa fa-phone"></i> Toll Free: 800 OMEGA (66342)</p>
                                    <p><i className="fa fa-fax"></i> FAX : +971 (0)4 357 33 88 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <Footer />
            <ScrollToTopManual />
        </>
    );
};

export default ContactUs;