import React from 'react';
import { Backdrop } from "@material-ui/core";
import RingLoader from "react-spinners/RingLoader";
// import CircularProgress from 'react-bootstrap'

const SpinnerComponent = (props) => {
    return (
        <Backdrop
          sx={{
            color: "#3455b0",
            backgroundColor: "rgb(255, 255, 255, 1)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={props.open}
        >
            <RingLoader color="#4200d1" size="60" />
        </Backdrop>
      );
}

export default SpinnerComponent;