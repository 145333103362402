import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PUBLIC_URL } from '../../../CommonString';
import OTPInput, { ResendOTP } from "otp-input-react";
const dayList = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
  {
    value: 11,
  },
  {
    value: 12,
  },
  {
    value: 13,
  },
  {
    value: 14,
  },
  {
    value: 15,
  },
  {
    value: 16,
  },
  {
    value: 17,
  },
  {
    value: 18,
  },
  {
    value: 19,
  },
  {
    value: 20,
  },
  {
    value: 21,
  },
  {
    value: 22,
  },
  {
    value: 23,
  },
  {
    value: 24,
  },
  {
    value: 25,
  },
  {
    value: 26,
  },
  {
    value: 27,
  },
  {
    value: 28,
  },
  {
    value: 29,
  },
  {
    value: 30,
  },
  {
    value: 31,
  }
];

const monthList = [
  {
    text: 'January',
    value: 1
  },
  {
    text: 'February',
    value: 2
  },
  {
    text: 'March',
    value: 3
  },
  {
    text: 'April',
    value: 4
  },
  {
    text: 'May',
    value: 5
  },
  {
    text: 'June',
    value: 6
  },
  {
    text: 'July',
    value: 7
  },
  {
    text: 'August',
    value: 8
  },
  {
    text: 'September',
    value: 9
  },
  {
    text: 'October',
    value: 10
  },
  {
    text: 'November',
    value: 11
  },
  {
    text: 'December',
    value: 12
  },
];

let years = [];
let date = new Date();
let nextDate = new Date(date.getFullYear() + 1, 1, 1);
let thisYear = nextDate.getFullYear();

for(let i = 0; i < 100; i++){
  
  date = new Date(thisYear - 1, 1, 1);
  thisYear = date.getFullYear();

  let year = {
    year: thisYear,
  };

  years = [...years, year];

}

const CustomerDetails = (props) => {

    const customerDetailsValue = props.customerDetailsValues;

    const [name, setName] = useState(customerDetailsValue && customerDetailsValue.name ? customerDetailsValue.name : '');
    const [email, setEmail] = useState(customerDetailsValue && customerDetailsValue.email ? customerDetailsValue.email : '');
    const [mobile, setMobile] = useState(customerDetailsValue && customerDetailsValue.mobile ? customerDetailsValue.mobile : '');
    const [day, setDay] = useState(customerDetailsValue && customerDetailsValue.day ? customerDetailsValue.day : '');
    const [month, setMonth] = useState(customerDetailsValue && customerDetailsValue.month ? customerDetailsValue.month : '');
    const [year, setYear] = useState(customerDetailsValue && customerDetailsValue.year ? customerDetailsValue.year : '');
    const [gender, setGender] = useState(customerDetailsValue && customerDetailsValue.gender ? customerDetailsValue.gender : '');
    const [nationality, setNationality] = useState(customerDetailsValue && customerDetailsValue.nationality ? customerDetailsValue.nationality : '');
    const [dob, setDob] = useState(customerDetailsValue && customerDetailsValue.dob ? customerDetailsValue.dob : '');
    const [countryList, setCountryList] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [nationalityId, setNationalityId] = useState(customerDetailsValue.nationalityId ? customerDetailsValue.nationalityId : '');
    const [smoker, setSmoker] = useState(customerDetailsValue && customerDetailsValue.smoker ? customerDetailsValue.smoker : '');
        
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [dobError, setDobError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [nationalityError, setNationalityError] = useState('');
    const [smokerError, setSmokerError] = useState('');
    const [categoryId, setCategoryId] = useState(props.categoryId);
    const [OTP, setOTP] = useState("");
    const [OTPError, setOTPError] = useState("");
    const [verficationId, setverficationId] = useState([]);

    const pageUpdate = () => {
      
      if(categoryId === 1){

        if(name !== '' && email !== '' && mobile !== '' && dob !== '' && gender !== '' && nationalityId !== '' ){
          // let newDob = year + '/' + month + '/' + day;
          // let date = new Date(newDob);
          // let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

          // setDob(currectDob);
          
          if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email) && isNaN(+mobile) == false && mobile.length == 9 && mobile[0] != 0){
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let currentDay = currentDate.getDate();
            let currentMonth = currentDate.getMonth() + 1;

            if(year == currentYear){

              if(month <= currentMonth){

                if(day <= currentDay){

                  props.customerDetails({
                    name: name,
                    email: email,
                    mobile: mobile,
                    day: day,
                    month: month,
                    year: year,
                    gender: gender,
                    nationality: nationality,
                    nationalityId: nationalityId,
                    dob: dob,
                  });
      
                  props.pageUpdate();
                }
                else{
                  setDobError('Select a valid Date of birth');
                }
              }
              else{
                setDobError('Select a valid Date of birth');
              }
            }
            else{
              props.customerDetails({
                name: name,
                email: email,
                mobile: mobile,
                day: day,
                month: month,
                year: year,
                gender: gender,
                nationality: nationality,
                nationalityId: nationalityId,
                dob: dob,
              });

              props.pageUpdate();
            }
          }
          else{
            if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email))
              setEmailError('');
            else
              setEmailError('Enter a valid email');

            if(mobile.length != 9){
              setMobileError('Mobile must be 9 digits');
            }
            else if(mobile[0] == 0){
              setMobileError('Avoid 0 at the begining');
            }
            else if(isNaN(+mobile) == true){
              setMobileError('Enter a valid mobile')
            }
            else{
              setMobileError('');
            }
          }
        }
        else{
          if(name === ''){
                  
            setNameError('Name field is required');
          }
          else{
            setNameError('');
          }
          if(email === ''){
              setEmailError('Email field is required');
          }
          else{
            if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
              setEmailError('');
            }
            else{
              setEmailError('Enter a valid email');
            }
          }
          if(mobile === ''){
              setMobileError('Mobile field is required');
          }
          else{
            setMobileError('');
          }
          if(dob === ''){
              setDobError('Date of birth is required');
          }
          else{
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let currentDay = currentDate.getDate();
            let currentMonth = currentDate.getMonth() + 1;

            if(year == currentYear){

              if(month <= currentMonth){
  
                if(day <= currentDay){
  
                  setDobError('');
                }
                else{
                  setDobError('Select a valid Date of birth');
                }
              }
              else{
                setDobError('Select a valid Date of birth');
              }
            }
            else{
              setDobError('');
            }

          }
          if(gender === ''){
              setGenderError('Gender is required');
          }
          else{
            setGenderError('');
          }
          if(nationalityId === ''){

              setNationalityError('Nationality is required');
          }
          else{
            setNationalityError('');
          }
        }
      }
      else if(categoryId == 6){

        if(name !== '' && email !== '' && mobile !== '' && dob !== '' && gender !== '' && nationalityId !== '' && smoker !== ''){
          // let newDob = year + '/' + month + '/' + day;
          // let date = new Date(newDob);
          // let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

          // setDob(currectDob);
          if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email) && mobile.length == 9){

            props.customerDetails({
              name: name,
              email: email,
              mobile: mobile,
              day: day,
              month: month,
              year: year,
              gender: gender,
              nationality: nationality,
              nationalityId: nationalityId,
              dob: dob,
              smoker: smoker,
            });

            props.pageUpdate();
          }
          else{

            if(!/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
              setEmailError('Must be an email');
            }
            if(mobile.length != 9){
                setMobileError('Enter a valid mobile');
            }
          }
        }
        else{
          if(name === ''){
                  
            setNameError('Name field is required');
          }
          if(email === ''){
              setEmailError('Email field is required');
          }
          if(mobile === ''){
              setMobileError('Mobile field is required');
          }
          if(dob === ''){
              setDobError('Date of birth is required');
          }
          if(gender === ''){
              setGenderError('Gender is required');
          }
          if(nationalityId === ''){

              setNationalityError('Nationality is required');
          }
          if(smoker === ''){
            setSmokerError('Smoker status is required');
          }
          else{
            setSmokerError('');
          }
        }

      }
      else{

        if(name !== '' && email !== '' && mobile !== ''){
         
          if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email) && mobile.length == 9){

            props.customerDetails({
              name: name,
              email: email,
              mobile: mobile,
              day: day,
              month: month,
              year: year,
              gender: gender,
              nationality: nationality,
              nationalityId: nationalityId,
              dob: dob,
            });

            props.pageUpdate();
          }
          else{
            if(!/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
              setEmailError('Must be an email');
            }
            if(mobile.length != 9){
                setMobileError('Enter a valid mobile');
            }
          }

        }
        else{
          if(name === ''){
                  
            setNameError('Name field is required');
          }
          if(email === ''){
              setEmailError('Email field is required');
          }
          if(mobile === ''){
              setMobileError('Mobile field is required');
          }
        }

      }
    }

    const pageDown = () => {
      
      let newDob = year + '/' + month + '/' + day;
      let date = new Date(newDob);
      let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

      setDob(currectDob);

      props.pageDown();

      props.customerDetails({
        name: name,
        email: email,
        mobile: mobile,
        day: day,
        month: month,
        year: year,
        gender: gender,
        nationality: nationality,
        nationalityId: nationalityId,
        dob: dob,
        smoker: smoker,
      });

    }

    const inputChange = (e) => {
      if(e.target.name === 'name'){
        setName(e.target.value);
        if(e.target.value === ''){
          setNameError('Name field is required');
        }
        else{
          setNameError('');

          let newDob = year + '/' + month + '/' + day;
          let date = new Date(newDob);
          let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

          setDob(currectDob);

          props.customerDetails({
            name: e.target.value,
            email: email,
            mobile: mobile,
            day: day,
            month: month,
            year: year,
            gender: gender,
            nationality: nationality,
            nationalityId: nationalityId,
            dob: dob,
            smoker: smoker,
          });
        }
      }
      if(e.target.name === 'email'){
        setEmail(e.target.value);
        if(e.target.value === ''){
          setEmailError('Email field is required');
        }
        else{
          if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(e.target.value)){

            setEmailError('');
            
            let newDob = year + '/' + month + '/' + day;
            let date = new Date(newDob);
            let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

            setDob(currectDob);

            props.customerDetails({
              name: name,
              email: e.target.value,
              mobile: mobile,
              day: day,
              month: month,
              year: year,
              gender: gender,
              nationality: nationality,
              nationalityId: nationalityId,
              dob: year,
            });
          }
          else{
            setEmailError('Enter a valid email');
          }
        }
      }
      if(e.target.name === 'mobile'){
        if(e.target.value.length <= 9 && isNaN(+e.target.value) == false && e.target.value[0] != 0){

          setMobile(e.target.value);
          if(e.target.value === ''){
            setMobileError('Mobile field is required');
          }
          else{

            if(e.target.value < 0){

              setMobileError('Must be mobile number');

            }
            else if(isNaN(+e.target.value) == true){
              setMobileError('Must be mobile number');
            }
            else if(e.target.value[0] == 0){
              setMobileError('Avoid 0 at the begining');
            }
            else{
              
                setMobileError('');
                
                let newDob = year + '/' + month + '/' + day;
                let date = new Date(newDob);
                let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

                setDob(currectDob);

                props.customerDetails({
                  name: name,
                  email: email,
                  mobile: e.target.value,
                  day: day,
                  month: month,
                  year: year,
                  gender: gender,
                  nationality: nationality,
                  nationalityId: nationalityId,
                  dob: dob,
                });
              
            }
          }
        }
      }
      if(e.target.name === 'day'){
        setDay(e.target.value);

        let newDob = year + '/' + month + '/' + e.target.value;
        let date = new Date(newDob);
        let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

        setDob(currectDob);

        props.customerDetails({
          name: name,
          email: email,
          mobile: mobile,
          day: e.target.value,
          month: month,
          year: year,
          gender: gender,
          nationality: nationality,
          nationalityId: nationalityId,
          dob: currectDob,
        });
      }
      if(e.target.name === 'month'){
        setMonth(e.target.value);

        let newDob = year + '/' + e.target.value + '/' + day;
        let date = new Date(newDob);
        let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

        setDob(currectDob);

        props.customerDetails({
          name: name,
          email: email,
          mobile: mobile,
          day: day,
          month: e.target.value,
          year: year,
          gender: gender,
          nationality: nationality,
          nationalityId: nationalityId,
          dob: currectDob,
        });
      }
      if(e.target.name === 'year'){
        setYear(e.target.value);

        let newDob = e.target.value + '/' + month + '/' + day;
        let date = new Date(newDob);
        let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

        setDob(currectDob);

        props.customerDetails({
          name: name,
          email: email,
          mobile: mobile,
          day: day,
          month: month,
          year: e.target.value,
          gender: gender,
          nationality: nationality,
          nationalityId: nationalityId,
          dob: currectDob,
        });
      }
      // if(e.target.name === 'dob'){
      //   setDob(e.target.value);

      //   props.customerDetails({
      //         name: name,
      //         email: email,
      //         mobile: mobile,
      //         day: day,
      //         month: month,
      //         year: year,
      //         gender: gender,
      //         nationality: nationality,
      //         nationalityId: nationalityId,
      //         dob: e.target.value,
      //     });
      // }
      if(e.target.name === 'gender'){
        setGender(e.target.value);
        if(e.target.value === ''){
          setGenderError('Gender field is required');
        }
        else{
          setGenderError('');
          

          let newDob = year + '/' + month + '/' + day;
          let date = new Date(newDob);
          let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

          setDob(currectDob);

          props.customerDetails({
            name: name,
            email: email,
            mobile: mobile,
            day: day,
            month: month,
            year: year,
            gender: e.target.value,
            nationality: nationality,
            nationalityId: nationalityId,
            dob: dob,
          });
        }
      }
      if(e.target.name === 'nationality'){
        setNationalityId(e.target.value);
        if(e.target.value === ''){
          setNationalityError('Nationality field is required');
        }
        else{
          
          setNationality('');
          setNationalityError('');

          let newDob = year + '/' + month + '/' + day;
          let date = new Date(newDob);
          let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

          setDob(currectDob);

          props.customerDetails({
            name: name,
            email: email,
            mobile: mobile,
            day: day,
            month: month,
            year: year,
            gender: gender,
            nationality: nationality,
            nationalityId: e.target.value,
            dob: dob,
          });
        }
      }
      if(e.target.name === 'smoker'){
        setSmoker(e.target.value);
        if(e.target.value === ''){
          setSmokerError('Smoker status field is required');
        }
        else{
          setSmokerError('');
          

          let newDob = year + '/' + month + '/' + day;
          let date = new Date(newDob);
          let currectDob = date.getDate() + "-"+ parseInt(date.getMonth()+1) +"-"+ date.getFullYear();

          setDob(currectDob);

          props.customerDetails({
            name: name,
            email: email,
            mobile: mobile,
            day: day,
            month: month,
            year: year,
            gender: e.target.value,
            nationality: nationality,
            nationalityId: nationalityId,
            dob: dob,
            smoker: e.target.value,
          });
        }
      }
    }

    useEffect(() => {
      
      if(firstLoad === true){

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

        axios({
          url: `${PUBLIC_URL}/country/list`,
          method: 'POST',
        }).then(response => {

          if(response.data.status === 'success'){
            setCountryList(response.data.data);
          }

        }).catch((error) => {});
      }
      
      setFirstLoad(false);

    });

    const backEnable = props.backEnable;
    const mystyle = {
      width: "50px",
      height: "50px",
      backgroundColor: "lighten(#0f0f1a, 5%)",
      // border: "none",
      lineHeight: "50px",
      textAlign: "center",
      fontSize: "24px",
      display: "initial",
      fontWeight: "200",
      color: "#172f3b",
      margin:"0 2px",
    };
    
    const renderButton = (buttonProps) => {
      return (
        <button {...buttonProps}>
          {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : "Resend"}
        </button>
      );
    }

    const renderTime = () => React.Fragment

    const extension = {
      position: 'relative',
      top: '30px',
      left: '5px',
      backgroundColor: '#d4d4fa',
      width: '9%',
      textAlign: 'center',
    }

    return (
        <div className="form-container animated active flipInX">
          <h2 className="text-center form-title">Customer Details</h2>
          <div className="form-group">
            <input type="text" className="form-control" onChange={inputChange} value={name} name="name" placeholder="Customer name" />
            {nameError ? <div className="text-danger">{nameError}</div> : ''}
          </div>
          <div className="form-group">
            <input type="email" className="form-control" onChange={inputChange} value={email} name="email" placeholder="Email" />
            {emailError ? <div className="text-danger">{emailError}</div> : ''}
          </div>
          <div className="form-group">
            <div style={extension}>+971</div>
            <input type="mobile" className="form-control mobile" onChange={inputChange} value={mobile} name="mobile" placeholder="Mobile" />
            {/* <small className="text-danger">Warning : Enter 9 digits of mobile number and avoid 0 at the begining</small> */}
            {mobileError ? <div className="text-danger">{mobileError}</div> : ''}
          </div>
          {categoryId === 1 || categoryId == 6 ?
          <>
            
            <fieldset>
              <legend style={{fontSize: '18px'}}>Date Of Birth</legend>
              <div className="field-inline-block">
                
                {/* <input type="text" name="day" onChange={inputChange} pattern="[0-9]*" value={day} maxlength="2" size="2" className="date-field" /> */}
                <select className="form-control" name="day" onChange={inputChange}>
                  <option value="">DD</option>
                  {dayList.map((list, index) => {
                    if (list.value == day)
                      return <option selected key={index} value={list.value}>{list.value}</option>
                    else
                      return <option key={index} value={list.value}>{list.value}</option>
                  })}
                </select>
              </div>
              /
              <div className="field-inline-block">
                {/* <input type="text" name="month" onChange={inputChange} pattern="[0-9]*" value={month} maxlength="2" size="2" className="date-field" /> */}
                <select className="form-control" name="month" onChange={inputChange}>
                  <option value="">MM</option>
                  {monthList.map((list, index) => {
                    if(list.value == month)
                      return <option selected key={index} value={list.value}>{list.text}</option>
                    else
                      return <option key={index} value={list.value}>{list.text}</option>
                  })}
                </select>
              </div>
              /
              <div className="field-inline-block">
                {/* <input type="text" name="year" onChange={inputChange} pattern="[0-9]*" value={year} maxlength="4" size="4" className="date-field date-field--year" /> */}
                <select className="form-control" name="year" onChange={inputChange}>
                  <option value="">YYYY</option>
                  {years.map((list, index) => {
                    
                    if(list.year == year)
                      return <option selected key={index} value={list.year}>{list.year}</option>
                    else
                      return <option key={index} value={list.year}>{list.year}</option>
                    
                  })}
                </select>
              </div>
              {dobError ? <div className="text-danger">{dobError}</div> : ''}
            </fieldset>
            <fieldset className="p-4">
              <div>
                <legend className="font-bold">Gender</legend>
              </div>
              <div className="inline-flex">
                {gender === 'male' ? <input className="hidden mr-1" checked onClick={inputChange} type="radio" id="male" value="male" name="gender"/>
                : <input className="hidden mr-1" onClick={inputChange} type="radio" id="male" value="male" name="gender"/> }
                <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-l" for="male">Male</label>
                {gender === 'female' ? <input className="hidden mr-1 ml-2" checked onClick={inputChange} type="radio" id="female" value="female" name="gender" />
                : <input className="hidden mr-1 ml-2" onClick={inputChange} type="radio" id="female" value="female" name="gender" />}
                <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer" for="female">Female</label>
                {gender === 'other' ? <input className="hidden mr-1 ml-2" checked onClick={inputChange} type="radio" id="non-binary" value="non-binary" name="gender" />
                : <input className="hidden mr-1 ml-2" onClick={inputChange} type="radio" id="non-binary" value="other" name="gender" />}
                <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-r" for="non-binary">Others</label>
              </div>
              {genderError ? <div className="text-danger">{genderError}</div> : ''}
            </fieldset>
            <select name="format" name="nationality" id="format" onChange={inputChange} style={{marginBottom: '20px'}}>
              <option>Nationality</option>
              {countryList && countryList.map((list, index) => {
                if(list.name === nationalityId)
                  return <option selected key={index} value={list.id}>{list.name}</option>
                else
                  return <option key={index} value={list.id}>{list.name}</option>
              })}
            </select>
            {nationalityError ? <div className="text-danger">{nationalityError}</div> : ''} </> :''}

            {categoryId == 6 ?
            
            <fieldset className="p-4 mb-2">
              <div>
                <legend className="font-bold">Smoker Status</legend>
              </div>
              <div className="inline-flex">
                {smoker === 'Yes' ? <input className="hidden mr-1" checked onClick={inputChange} type="radio" id="SmokerYes" value="Yes" name="smoker"/>
                : <input className="hidden mr-1" onClick={inputChange} type="radio" id="SmokerYes" value="Yes" name="smoker"/> }
                <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-l" for="SmokerYes">Yes</label>
                {smoker === 'No' ? <input className="hidden mr-1 ml-2" checked onClick={inputChange} type="radio" id="SmokerNo" value="No" name="smoker" />
                : <input className="hidden mr-1 ml-2" onClick={inputChange} type="radio" id="SmokerNo" value="No" name="smoker" />}
                <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer" for="SmokerNo">No</label>
                
              </div>
              {smokerError ? <div className="text-danger">{smokerError}</div> : ''}
            </fieldset>

            : ''}
                          
          <div className="form-group text-center mar-b-0 ">
            {backEnable ? <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" /> : ''}    
            <input type="submit" onClick={pageUpdate} value="SUBMIT" className="btn btn-primary submit" />     
          </div>
        </div>
    );
};

export default CustomerDetails;