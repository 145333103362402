export const PUBLIC_URL = 'https://beemabazaar-backend.beemabazaar.ae/api';
export const IMAGE_URL = 'https://beemabazaar-backend.beemabazaar.ae';

export const SMSAPISENDURL ='https://api.smsala.com/api/Verify';
export const SMSAPIVERIFYURL ='https://api.smsala.com/api/VerifyStatus';
export const api_id='API866985752794';
export const api_password='shSMjZUqRN';
export const brand='Prompttech';
export const sender_id='RB-UAE';
// export const PUBLIC_URL = 'http://localhost:8000/api';
// export const IMAGE_URL = 'http://localhost:8000';

// export const PUBLIC_URL = 'http://test-backend-beemabazaar.prompttechdemohosting.com/api';
// export const IMAGE_URL = 'http://test-backend-beemabazaar.prompttechdemohosting.com';