import axios from 'axios';
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import Swal from 'sweetalert2';
import { PUBLIC_URL, SMSAPISENDURL, SMSAPIVERIFYURL, api_id, brand, api_password, sender_id } from '../../../CommonString';
import CustomerDetails from '../../carinsurance/english/CustomerDetails';
import SpinnerComponent from '../../common/SpinnerComponent';
import Footer from '../../layout/english/Footer';
import Header from '../../layout/english/Header';
import OTPInput, { ResendOTP } from "otp-input-react";

const dayList = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
  {
    value: 11,
  },
  {
    value: 12,
  },
  {
    value: 13,
  },
  {
    value: 14,
  },
  {
    value: 15,
  },
  {
    value: 16,
  },
  {
    value: 17,
  },
  {
    value: 18,
  },
  {
    value: 19,
  },
  {
    value: 20,
  },
  {
    value: 21,
  },
  {
    value: 22,
  },
  {
    value: 23,
  },
  {
    value: 24,
  },
  {
    value: 25,
  },
  {
    value: 26,
  },
  {
    value: 27,
  },
  {
    value: 28,
  },
  {
    value: 29,
  },
  {
    value: 30,
  },
  {
    value: 31,
  }
];

const insuranceFor = [
  {
    value: 'Self',
  },
  {
    value: 'Family',
  },
  {
    value: 'Group of Employees / Corporate',
  },
];

const monthList = [
  {
    text: 'January',
    value: 1
  },
  {
    text: 'February',
    value: 2
  },
  {
    text: 'March',
    value: 3
  },
  {
    text: 'April',
    value: 4
  },
  {
    text: 'May',
    value: 5
  },
  {
    text: 'June',
    value: 6
  },
  {
    text: 'July',
    value: 7
  },
  {
    text: 'August',
    value: 8
  },
  {
    text: 'September',
    value: 9
  },
  {
    text: 'October',
    value: 10
  },
  {
    text: 'November',
    value: 11
  },
  {
    text: 'December',
    value: 12
  },
];

const emiratesList = [
  {
    value: 'Abu Dhabi',
  },
  {
    value: 'Dubai',
  },
  {
    value: 'Sharjah',
  },
  {
    value: 'Ajman',
  },
  {
    value: 'Umm Al-Quwain',
  },
  {
    value: 'Fujairah',
  },
  {
    value: 'Ras Al Khaimah',
  },
]

let years = [];
let date = new Date();
let nextDate = new Date(date.getFullYear() - 1, 1, 1);
let thisYear = nextDate.getFullYear();

for (let i = 10; i > 0; i--) {

  date = new Date(thisYear + 1, 1, 1);
  thisYear = date.getFullYear();

  let year = {
    year: thisYear,
  };

  years = [...years, year];

}

let years1 = [];
let date1 = new Date();
let nextDate1 = new Date(date1.getFullYear() + 1, 1, 1);
let thisYear1 = nextDate1.getFullYear();

for (let i = 0; i < 100; i++) {

  date1 = new Date(thisYear1 - 1, 1, 1);
  thisYear1 = date1.getFullYear();

  let year1 = {
    year: thisYear1,
  };

  years1 = [...years1, year1];

}

const Main = (props) => {

  let categoryName = ((new URLSearchParams(props.location.search).get('category')) != '') ? (new URLSearchParams(props.location.search).get('category')) : '';
  let categoryId = ((new URLSearchParams(props.location.search).get('id')) != '') ? (new URLSearchParams(props.location.search).get('id')) : '';
  const [firstLoad, setFirstLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [dob, setDob] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [emirate, setEmirate] = useState('');
  const [health, setHealth] = useState('');
  const [haveInsurance, setHaveInsurance] = useState('');
  const [virginGroup, setVirginGroup] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState('');

  const [expiryError, setExpiryError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [dobError, setDobError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [healthError, setHealthError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emirateError, setEmirateError] = useState('');
  const [expiryDateError, setExpiryDateError] = useState('');
  const [virginGroupError, setVirginGroupError] = useState('');
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [verficationId, setverficationId] = useState([]);
  const [verifiedOtp, setVerifiedOtp] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const history = useHistory();
  const headers = ""





  const pageUpdate = () => {
    //  console.log(page,"page")
    if (page === 2) {

      if (name !== '' && email !== '' && mobile !== '' && dob !== '' && emirate !== '') {
        
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentDay = currentDate.getDate();
        let currentMonth = currentDate.getMonth() + 1;

        if(year == currentYear){

          if(month <= currentMonth){

            if(day <= currentDay){

              setDobError('');
            }
            else{
              setDobError('Select a valid Date of birth');
            }
          }
          else{
            setDobError('Select a valid Date of birth');
          }
        }
        else{

          if(mobile[0] == 0){
            setMobileError('Avoid 0 at the begining');
          }
          else if(isNaN(+mobile) == true){
            setMobileError('Enter a valid mobile');
          }
          else{
  
            setPage(4);
          }

          setDobError('');
        }

        if(mobile[0] == 0){
          setMobileError('Avoid 0 at the begining');
        }
        else if(isNaN(+mobile) == true){
          setMobileError('Enter a valid mobile');
        }
        else{

          if(year == currentYear){

            if(month <= currentMonth){
  
              if(day <= currentDay){
  
                setDobError('');
              }
              else{
                setDobError('Select a valid Date of birth');
              }
            }
            else{
              setDobError('Select a valid Date of birth');
            }
          }
          else{
             
            setDobError('');
          }

          let otpData = new FormData();
          otpData.append('mobile', mobile);

          axios({
            url: `${PUBLIC_URL}/generate/otp`,
            method: 'POST',
            data: otpData,

          }).catch((error) => {});

          setPage(4);
        }
        //   axios({
        //     url: `${SMSAPISENDURL}`,
        //     method: 'POST',
        //     data: {
        //       api_id:"API866985752794", 
        //       api_password:"shSMjZUqRN", 
        //       brand: "Prompttech", 
        //       phonenumber: mobile, 
        //       sender_id: "RB-UAE",
        //       extmessage:"Your RunBazaar Portal Verfication Code is : 2958"
        //     },
        //     headers: {"Access-Control-Allow-Origin": "*"}

        // }).then(response => {

        //   console.log(response,"responce value")
        //     setLoading(false);
        // if(response.data.status === 'success'){
        //   setverficationId
        // setPage(4);

        // }
        // else{
        //     Swal.fire({
        //         title: 'error!',
        //         text: response.data.message,
        //         icon: 'error',
        //     });
        // }

        // }).catch((error) => {
        // setLoading(false);
        // Swal.fire({
        //     title: 'Error!',
        //     text: 'Something went wrong',
        //     icon: 'error',
        // });

        // });

      }
      else {

        if (name === '') {
          setNameError('Name field is required');
        }
        else{
          setNameError('');
        }

        if (email === '') {
          setEmailError('Email field is required');
        }
        else{
          if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
            setEmailError('');
          }
          else{
            setEmailError('Enter a valid email');
          }
        }

        if (mobile === '') {
          setMobileError('Mobile field is required');
        }
        else{
          if(mobile[0] == 0){
            setMobileError('Avoid 0 at the begining');
          }
          else if(isNaN(+mobile) == true){
            setMobileError('Enter a valid mobile');
          }
          else{
            setMobileError('');
          }
        }

        if (dob === '') {
          setDobError('Date of birth is required');
        }
        else{

          let currentDate = new Date();
          let currentYear = currentDate.getFullYear();
          let currentDay = currentDate.getDate();
          let currentMonth = currentDate.getMonth() + 1;

          if(year == currentYear){

            if(month <= currentMonth){
  
              if(day <= currentDay){
  
                setDobError('');
              }
              else{
                setDobError('Select a valid Date of birth');
              }
            }
            else{
              setDobError('Select a valid Date of birth');
            }
          }
          else{
            
            if(mobile[0] == 0){
              setMobileError('Avoid 0 at the begining');
            }
            else if(isNaN(+mobile) == true){
              setMobileError('Enter a valid mobile');
            }
            else{
              setDobError('');
              
            }
  
          }
        }

        if (emirate === '') {
          setEmirateError('Emirate of visa issuance is required');
        }
        else{
          setEmirateError('');
        }
      }
    }
    else if (page === 3) {

      if (name !== '' && email !== '' && mobile !== '' && (expiryDate !== '' || virginGroup !== '')) {

        if(mobile[0] == 0){
          setMobileError('Avoid 0 at the begining');
        }
        else if(isNaN(+mobile) == true){
          setMobileError('Enter a valid email');
        }
        else{

          let otpData = new FormData();
          otpData.append('mobile', mobile);

          axios({
            url: `${PUBLIC_URL}/generate/otp`,
            method: 'POST',
            data: otpData,

          }).catch((error) => {});

          setPage(5);
        }

      }
      else {

        if (name === '') {
          setNameError('Company name field is required');
        }

        if (email === '') {
          setEmailError('Email field is required');
        }

        if (mobile === '') {
          setMobileError('Mobile field is required');
        }

        if (expiryDate === '') {
          setDobError('Expiry date is required');
        }

      }
    }
    else if (page === 4) {
      let verifyData = new FormData();
      let verified_otp = '';

      verifyData.append('mobile', mobile);
      verifyData.append('otp', OTP);

      setLoading(true);
      setDisableButton(true);

      axios({
        url: `${PUBLIC_URL}/verify/otp`,
        method: 'POST',
        data: verifyData,

      }).then(response => {

        if(response.data.status === 'success'){

          setDisableButton(true);
          setVerifiedOtp(response.data.otp);
          verified_otp = response.data.otp;

          if(OTP == verified_otp){

            setOTPError('');
            setLoading(true);

            axios({
              url: `${PUBLIC_URL}/enquiry/store`,
              method: 'POST',
              data: {
                category: categoryId,
                ip_address: ipAddress,
                name: name,
                email: email,
                phone: mobile,
                date_of_berth: dob ? dob : null,
                health: health,
                emirate: emirate,
              },
            }).then(response => {
              
              if (response.data.status === 'success') {
                setLoading(false);
                Swal.fire({
                  title: '<strong>Thank You</strong>',
                  icon: 'success',
                  html:
                    'One of our Member is working on your custom quote and will be in touch shortly.',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText:
                    '<i className="fa fa-thumbs-up"></i> Go to home page!',
                  confirmButtonAriaLabel: 'Thumbs up, great!',

                }).then((result) => {
                  history.push('/');
                });

              }
              else {
                Swal.fire({
                  title: 'error!',
                  text: response.data.message,
                  icon: 'error',
                });
              }

            }).catch((error) => {
              setLoading(false);
              Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
              });

            });
          }
        }
        else{
          setDisableButton(false);
          setLoading(false);
          setOTPError(response.data.message)
        }
      }).catch((error) => {
        setLoading(false);
      });

    }
    else if (page === 5) {

      let verifyData = new FormData();
      let verified_otp = '';

      verifyData.append('mobile', mobile);
      verifyData.append('otp', OTP);

      setLoading(true);
      setDisableButton(true);

      axios({
        url: `${PUBLIC_URL}/verify/otp`,
        method: 'POST',
        data: verifyData,

      }).then(response => {

        if(response.data.status === 'success'){

          setDisableButton(true);
          setVerifiedOtp(response.data.otp);
          verified_otp = response.data.otp;

          if(OTP == verified_otp){
            setOTPError('')
            setLoading(true);
            // setLoading(true);
            axios({
              url: `${PUBLIC_URL}/enquiry/store`,
              method: 'POST',
              data: {
                category: categoryId,
                ip_address: ipAddress,
                name: name,
                email: email,
                phone: mobile,
                health: health,
                expiry_date: expiryDate,
                virgin_group: virginGroup,
                have_insurance: haveInsurance,
              },
            }).then(response => {
              setLoading(false);
              if (response.data.status === 'success') {

                Swal.fire({
                  title: '<strong>Thank You</strong>',
                  icon: 'success',
                  html:
                    'One of our Member is working on your custom quote and will be in touch shortly.',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText:
                    '<i className="fa fa-thumbs-up"></i> Go to home page!',
                  confirmButtonAriaLabel: 'Thumbs up, great!',

                }).then((result) => {
                  history.push('/');
                });

              }
              else {
                Swal.fire({
                  title: 'error!',
                  text: response.data.message,
                  icon: 'error',
                });
              }

            }).catch((error) => {
              setLoading(false);
              Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
              });

            });
          }
          
        }
        else{
          
          setDisableButton(false);
          setOTPError(response.data.message);
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
      });
      
    }
    else {

      if (health !== '') {

        if (health === 'Group of Employees / Corporate'){
          setPage(3);
        }
        else {
          setPage(page + 1);
          setHealthError('');
        }
      }
      else {
        setHealthError('Health insurance for field is required');
      }
    }
  }

  const pageDown = () => {
    if(page === 5 || page === 4){
      if(health === 'Group of Employees / Corporate')
        setPage(3);
      else
        setPage(2);
    }
    else
      setPage(1);
  }

  const inputChange = (e) => {

    if (e.target.name === 'health') {
      setHealth(e.target.value);
      if (e.target.value !== '') {
        setHealthError('');
      }
      else {
        setHealthError('Health insurance for field is required');
      }
    }


    if (e.target.name === 'name') {
      setName(e.target.value);
      if (e.target.value !== '') {
        setNameError('');
      }
      else {
        setNameError('Name field is required');
      }
    }


    if (e.target.name === 'day') {
      setDay(e.target.value);

      let newDob = year + '/' + month + '/' + e.target.value;
      let date = new Date(newDob);
      let currectDob = date.getDate() + "-" + parseInt(date.getMonth() + 1) + "-" + date.getFullYear();

      setDob(currectDob);
      setExpiryDate(currectDob);

      if (dob !== '') {
        setDobError('');
      }

      if (expiryDate !== '') {
        setExpiryDateError('');
      }
    }

    if (e.target.name === 'month') {
      setMonth(e.target.value);

      let newDob = year + '/' + e.target.value + '/' + day;
      let date = new Date(newDob);
      let currectDob = date.getDate() + "-" + parseInt(date.getMonth() + 1) + "-" + date.getFullYear();

      setDob(currectDob);
      setExpiryDate(currectDob);

      if (dob !== '') {
        setDobError('');
      }

      if (expiryDate !== '') {
        setExpiryDateError('');
      }

    }

    if (e.target.name === 'year') {
      setYear(e.target.value);

      let newDob = e.target.value + '/' + month + '/' + day;
      let date = new Date(newDob);
      let currectDob = date.getDate() + "-" + parseInt(date.getMonth() + 1) + "-" + date.getFullYear();

      setDob(currectDob);
      setExpiryDate(currectDob);

      if (dob !== '') {
        setDobError('');

      }

      if (expiryDate !== '') {
        setExpiryDateError('');
      }
    }

    if (e.target.name === 'email') {

      setEmail(e.target.value);
      if (e.target.value !== '') {
        setEmailError('');
      }
      else {
        setEmailError('Email field is required');
      }
    }

    if (e.target.name === 'mobile') {

      if(e.target.value.length <= 9){

        setMobile(e.target.value);

        if (e.target.value !== '') {

          if(e.target.value < 0){
            setMobileError('Must be mobile number');
          }
          else if(isNaN(+e.target.value) == true){
            setMobileError('Must be mobile number');
          }
          else if(e.target.value[0] == 0){
            setMobileError('Avoid 0 at the begining');
          }
          else{
            setMobileError('');
          }
        }
        else {
          setMobileError('Mobile field is required');
        }
      }
    }

    if (e.target.name === 'emirate') {

      setEmirate(e.target.value);
      if (e.target.value !== '') {
        setEmirateError('');
      }
      else {
        setEmirateError('Emirate of visa issuance field is required');
      }
    }

    if (e.target.name === 'haveInsurance') {
      setHaveInsurance(e.target.value);
      if (e.target.value === 'Yes') {
        setVirginGroup('');
      }
      else if (e.target.value === 'No') {
        setExpiryDate('');
      }
    }

    if (e.target.name === 'virginGroup') {
      setVirginGroup(e.target.value);
      if (e.target.value !== '') {
        setVirginGroupError('');
      }
      else {
        setVirginGroupError('Virgin Group field is required');
      }
    }
  }

  const sendOtp = () => {

    let otpData = new FormData();
    otpData.append('mobile', mobile);
    setLoading(true);
    axios({
      url: `${PUBLIC_URL}/generate/otp`,
      method: 'POST',
      data: otpData,

    }).then(response => {

      if(response.data.status === 'success'){}
      setLoading(false);

    }).catch((error) => {
      setLoading(false);
    });
    
  }

  const mystyle = {
    width: "50px",
    height: "50px",
    backgroundColor: "lighten(#0f0f1a, 5%)",
    // border: "none",
    lineHeight: "50px",
    textAlign: "center",
    fontSize: "24px",
    display: "initial",
    fontWeight: "200",
    color: "#172f3b",
    margin: "0 2px",
  };

  const extension = {
    position: 'relative',
    top: '30px',
    left: '5px',
    backgroundColor: '#d4d4fa',
    width: '9%',
    textAlign: 'center',
  }

  const renderButton = (buttonProps) => {
    return (
      <button {...buttonProps}>
        {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : <a onClick={sendOtp}>Resend</a>}
      </button>
    );
  }

  const renderTime = () => React.Fragment;

  return (
    <>
      <Header />
      <SpinnerComponent open={loading} />

      <section className="other-insurance-form">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="steps-form">
                <div className="form-wizard">
                  <div className="steps">
                  </div>
                  <div className="myContainer" style={{ minWidth: '30vw', minHeight: '60vh' }}>
                    {page === 1 ?
                      <div className="form-container animated active flipInX">
                        <h2 className="text-center form-title">{categoryName}</h2>
                        <select name="health" onChange={inputChange} className="custom-select mb-3">
                          <option value="">Health Insurance Required For?</option>
                          {insuranceFor.map((option, index) => {
                            if (health === option.value)
                              return <option selected key={index} value={option.value}>{option.value}</option>
                            else
                              return <option key={index} value={option.value}>{option.value}</option>
                          })}
                        </select>
                        {healthError ? <div className="text-danger">{healthError}</div> : ''}
                        <div className="form-group text-center ">
                          {/* <input type="button" value="BACK" className="btn btn-default back mr-2" />  */}
                          <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />
                        </div>
                      </div>
                      : page === 2 ?
                        <div className="form-container animated active flipInX">
                          <h2 className="text-center form-title">{categoryName}</h2>
                          <input type="text" className="form-control" onChange={inputChange} value={name} name="name" placeholder="Customer name" />
                          {nameError ? <div className="text-danger">{nameError}</div> : ''}
                          <fieldset className="mt-3 mb-3">
                            <legend style={{ fontSize: '18px' }}>Date Of Birth</legend>
                            <div className="field-inline-block">

                              {/* <input type="text" name="day" onChange={inputChange} pattern="[0-9]*" value={day} maxlength="2" size="2" className="date-field" /> */}
                              <select className="form-control" name="day" onChange={inputChange}>
                                <option value="">DD</option>
                                {dayList.map((list, index) => {
                                  if (list.value == day)
                                    return <option selected key={index} value={list.value}>{list.value}</option>
                                  else
                                    return <option key={index} value={list.value}>{list.value}</option>
                                })}
                              </select>
                            </div>
                            /
                            <div className="field-inline-block">
                              {/* <input type="text" name="month" onChange={inputChange} pattern="[0-9]*" value={month} maxlength="2" size="2" className="date-field" /> */}
                              <select className="form-control" name="month" onChange={inputChange}>
                                <option value="">MM</option>
                                {monthList.map((list, index) => {
                                  if (list.value == month)
                                    return <option selected key={index} value={list.value}>{list.text}</option>
                                  else
                                    return <option key={index} value={list.value}>{list.text}</option>
                                })}
                              </select>
                            </div>
                            /
                            <div className="field-inline-block">
                              {/* <input type="text" name="year" onChange={inputChange} pattern="[0-9]*" value={year} maxlength="4" size="4" className="date-field date-field--year" /> */}
                              <select className="form-control" name="year" onChange={inputChange}>
                                <option value="">YYYY</option>
                                {years1.map((list, index) => {
                                  if (list.year == year)
                                    return <option key={index} value={list.year}>{list.year}</option>
                                  else
                                    return <option key={index} value={list.year}>{list.year}</option>
                                })}
                              </select>
                            </div>
                            {dobError ? <div className="text-danger">{dobError}</div> : ''}
                          </fieldset>
                          <div className="form-group">
                            <input type="email" className="form-control" onChange={inputChange} value={email} name="email" placeholder="Email" />
                            {emailError ? <div className="text-danger">{emailError}</div> : ''}
                          </div>
                          <div className="form-group">
                            <div style={extension}>+971</div>
                            <input type="mobile" className="form-control mobile" onChange={inputChange} value={mobile} name="mobile" placeholder="Mobile number" autoComplete="off" />
                            {/* <small className="text-danger">Warning : Enter 9 digits of mobile number and avoid 0 at the begining</small> */}
                            {mobileError ? <div className="text-danger">{mobileError}</div> : ''}
                          </div>
                          <select name="format" name="emirate" id="format" onChange={inputChange} style={{ marginBottom: '20px' }}>
                            <option>Emirates of Visa Issuance </option>
                            {emiratesList && emiratesList.map((list, index) => {
                              if (list.value === emirate)
                                return <option selected key={index} value={list.value}>{list.value}</option>
                              else
                                return <option key={index} value={list.value}>{list.value}</option>
                            })}
                          </select>
                          {emirateError ? <div className="text-danger">{emirateError}</div> : ''}
                          <div className="form-group text-center ">
                            <input type="button" value="BACK" onClick={pageDown} className="btn btn-default back mr-2" />
                            <input type="button" onClick={pageUpdate} value="Submit" className="btn btn-primary next" />
                          </div>
                        </div>
                        : page === 4 ?
                          <div className="form-container animated active flipInX">
                            <h2 className="text-center form-title">{categoryName}</h2>
                            <OTPInput
                              value={OTP}
                              onChange={setOTP}
                              autoFocus OTPLength={6}
                              otpType="number"
                              disabled={false}
                              inputStyles={mystyle}
                            />
                            {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                            <ResendOTP renderButton={renderButton} renderTime={renderTime} />
                            {OTPError ? <div className="text-danger">{OTPError}</div> : ''}
                            <div className="form-group text-center mar-b-0 ">
                              <input type="button" value="BACK" onClick={pageDown} className="btn btn-default back mr-2" />
                              {disableButton == false ? <input type="button" onClick={pageUpdate} value="Submit OTP" className="btn btn-primary next" />:
                              <input type="button" value="Loading..." className="btn btn-primary next disabled" />}
                            </div>


                            {/* <OTPInput
                                                  value={OTP}
                                                  onChange={setOTP}
                                                  autoFocus
                                                  OTPLength={4}
                                                  otpType="number"
                                                  disabled={false}
                                                  secure
                                                />
                                                <ResendOTP handelResendClick={() => console.log("Resend clicked")} />        */}
                          </div>
                          : page === 5 ?
                            <div className="form-container animated active flipInX">
                              <h2 className="text-center form-title">{categoryName}</h2>
                              
                              <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                autoFocus OTPLength={6}
                                otpType="number"
                                disabled={false}
                                inputStyles={mystyle}
                              />
                              {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                              <ResendOTP renderButton={renderButton} renderTime={renderTime} />
                              {OTPError ? <div className="text-danger">{OTPError}</div> : ''}
                              
                              <div className="form-group text-center mar-b-0 ">
                                <input type="button" value="BACK" onClick={pageDown} className="btn btn-default back mr-2" />
                                {disableButton == false ? <input type="button" onClick={pageUpdate} value="Submit OTP" className="btn btn-primary next" />:
                              <input type="button" value="Loading..." className="btn btn-primary next disabled" />}
                              </div>


                              {/* <OTPInput
                                                  value={OTP}
                                                  onChange={setOTP}
                                                  autoFocus
                                                  OTPLength={4}
                                                  otpType="number"
                                                  disabled={false}
                                                  secure
                                                />
                                                <ResendOTP handelResendClick={() => console.log("Resend clicked")} />        */}
                            </div>



                            :
                            <div className="form-container animated active flipInX">
                              <h2 className="text-center form-title">{categoryName}</h2>
                              <input type="text" className="form-control" onChange={inputChange} value={name} name="name" placeholder="Company name" />
                              <fieldset className="mt-3">
                                <legend className="font-bold">Already have an insurance ?</legend>
                                <div className="inline-flex">
                                  {haveInsurance === 'Yes' ? <input className="hidden mr-1" checked onClick={inputChange} type="radio" id="Yes" value="Yes" name="haveInsurance" />
                                    : <input className="hidden mr-1" onClick={inputChange} type="radio" id="Yes" value="Yes" name="haveInsurance" />}
                                  <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-l" for="Yes">Yes</label>
                                  {haveInsurance === 'No' ? <input className="hidden mr-1 ml-2" checked onClick={inputChange} type="radio" id="female" value="No" name="haveInsurance" />
                                    : <input className="hidden mr-1 ml-2" onClick={inputChange} type="radio" id="No" value="No" name="haveInsurance" />}
                                  <label className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer" for="No">No</label>
                                </div>
                              </fieldset>
                              {haveInsurance === 'Yes' ?
                                <fieldset className="mt-3 mb-3">
                                  <legend style={{ fontSize: '18px' }}>Expiry Date</legend>
                                  <div className="field-inline-block">

                                    {/* <input type="text" name="day" onChange={inputChange} pattern="[0-9]*" value={day} maxlength="2" size="2" className="date-field" /> */}
                                    <select className="form-control" name="day" onChange={inputChange}>
                                      <option value="">DD</option>
                                      {dayList.map((list, index) => {
                                        if (list.value == day)
                                          return <option selected key={index} value={list.value}>{list.value}</option>
                                        else
                                          return <option key={index} value={list.value}>{list.value}</option>
                                      })}
                                    </select>
                                  </div>
                                  /
                                  <div className="field-inline-block">
                                    {/* <input type="text" name="month" onChange={inputChange} pattern="[0-9]*" value={month} maxlength="2" size="2" className="date-field" /> */}
                                    <select className="form-control" name="month" onChange={inputChange}>
                                      <option value="">MM</option>
                                      {monthList.map((list, index) => {
                                        if (list.value == month)
                                          return <option selected key={index} value={list.value}>{list.text}</option>
                                        else
                                          return <option key={index} value={list.value}>{list.text}</option>
                                      })}
                                    </select>
                                  </div>
                                  /
                                  <div className="field-inline-block">
                                    {/* <input type="text" name="year" onChange={inputChange} pattern="[0-9]*" value={year} maxlength="4" size="4" className="date-field date-field--year" /> */}
                                    <select className="form-control" name="year" onChange={inputChange}>
                                      <option value="">YYYY</option>
                                      {years.map((list, index) => {
                                        if (list.year == year)
                                          return <option key={index} value={list.year}>{list.year}</option>
                                        else
                                          return <option key={index} value={list.year}>{list.year}</option>
                                      })}
                                    </select>
                                  </div>
                                  {expiryDateError ? <div className="text-danger">{expiryDateError}</div> : ''}
                                </fieldset>
                                : haveInsurance === 'No' ?
                                  <div className="form-group">
                                    <input type="text" className="form-control" onChange={inputChange} value={virginGroup} name="virginGroup" placeholder="Virgin Group" />
                                    {virginGroupError ? <div className="text-danger">{virginGroupError}</div> : ''}
                                  </div> : ''}
                              <div className="form-group">
                                <input type="email" className="form-control" onChange={inputChange} value={email} name="email" placeholder="Email" />
                                {emailError ? <div className="text-danger">{emailError}</div> : ''}
                              </div>
                              <div className="form-group">
                                <div style={extension}>+971</div>
                                <input type="mobile" className="form-control mobile" onChange={inputChange} value={mobile} name="mobile" placeholder="Mobile" />
                                {/* <small className="text-danger">Warning : Enter 9 digits of mobile number and avoid 0 at the begining</small> */}
                                {mobileError ? <div className="text-danger">{mobileError}</div> : ''}
                              </div>
                              <div className="form-group text-center ">
                                <input type="button" value="BACK" onClick={pageDown} className="btn btn-default back mr-2" />
                                <input type="button" onClick={pageUpdate} value="Submit" className="btn btn-primary next" />
                              </div>
                            </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Main;