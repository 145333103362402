import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from '../../../CommonString';
import ScrollToTopManual from '../../common/ScrollToTopManual';
import SpinnerComponent from '../../common/SpinnerComponent';
import Footer from '../../layout/english/Footer';
import HomeHeader from '../../layout/english/HomeHeader';
import Insurance from './Insurance';
import OurPartner from './OurPartner';
import Testimonial from './Testimonial';
import WhyChoose from './WhyChoose';

const Home = (props) => {

    const [firstLoad, setFirstLoad] = useState(true);
    const[category, setCategory] = useState([]);
    const [ourPartner, setOurPartner] = useState([]);
    const [testimonial, setTestimonial] = useState([]);
    const [loading, setLoading] = useState(false);

    let aboutMe = useRef(null);
    let partnerRef = useRef(null);
    let reviewRef = useRef(null);
    

    useEffect(() => {

        if(firstLoad === true){
            setLoading(true);
            axios({
                url: `${PUBLIC_URL}/home`,
                method: 'POST',
            }).then(response => {
                setLoading(false);
                if(response.data.status === 'success'){
                    
                    setCategory(response.data.data.category);
                    setOurPartner(response.data.data.partner);
                    setTestimonial(response.data.data.testimonial);
                }

            }).catch((error) => {
                setLoading(false);
            });
        }
        setFirstLoad(false);

        let location = window.location.href.split('#');

        if(location[2] === 'home'){
            window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
        }
        else if(location[2] === 'about'){
            window.scrollTo({ behavior: 'smooth', top: aboutMe.current.offsetTop - 100 });
        }
        else if(location[2] === 'partners'){
            window.scrollTo({ behavior: 'smooth', top: partnerRef.current.offsetTop - 100 });
        }
        else if(location[2] === 'reviews'){
            window.scrollTo({ behavior: 'smooth', top: reviewRef.current ? reviewRef.current.offsetTop -100 : 0 });
        }
    });

    const scrollToRef = (ref) => {
        if(ref.key === 'home')
            window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
        else if(ref.key === 'about'){
            window.scrollTo({ behavior: 'smooth', top: aboutMe.current.offsetTop - 100 });
        }
        else if(ref.key === 'partner')
            window.scrollTo({ behavior: 'smooth', top: partnerRef.current.offsetTop - 100 });
        else if(ref.key === 'review')
            window.scrollTo({ behavior: 'smooth', top: reviewRef.current.offsetTop -100 });
    }

    return (
        <div>
            <HomeHeader scrollToRef={scrollToRef} />
            <SpinnerComponent open={loading} />
            <Insurance category={category} />
            <div className="container" ref={aboutMe}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="buy mb-3">
                            <h1>Buy Insurance,</h1>
                            <h2>The Smart Way.</h2>
                            <p>You can now discover the best insurance for you, family or your company at the best price with Beemabazaar.</p>
                            <Link to="/generalinsurance?category=General Insurance">Get Your Quote</Link>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="quick">
                                    <img src="images/quick.png" className="img-fluid" />
                                    <h6>Quick, Easy &amp;<br />
                                        Hassle Free</h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="claim">
                                    <Link to="/claim-assistance" style={{color: '#000', textDecoration: 'none', ':hover': {textDecoration: 'none'}}}>
                                        <img src="images/claim.png" className="img-fluid" />
                                        <h6>Claim <br /> Assistance</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="image mb-2">
                            <h3>About Us</h3>
                            <p>We craft beautiful protection experiences. Thanks to our tremendous knowledge and expertise in the insurance industry, we create protection as the foundation of your happiness.</p>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <div className="omega">
                                    <h5>Comprehensive</h5>
                                    <p>Providing effective policies suitable for you, without holes and overlapping.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="omega">
                                    <h5>Claims Assured</h5>
                                    <p>We follow on your behalf with insurers to have your claims settled fast and fair.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <div className="omega">
                                    <h5>Cost-Effective</h5>
                                    <p>We scan the entire market to ensure you get cost-effective insurance solutions.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="omega">
                                    <h5>Client Centered</h5>
                                    <p>We design insurance solutions keeping you in our minds and ensuring peace of mind</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="image">
                            <img src="images/banner.png" className="img-fluid" />
                        </div> */}
                    </div>
                </div>
                <div className="row">
                   <div className="col-md-12">
                        <div className="about-row-content text-justify">
                            BeemaBazaar is passionate about offering you high-quality products and services that simplify your life and make it better. In order to make our insurance plans and services more accessible and easier to purchase, we've created diverse touchpoints. Through these touchpoints, you can obtain quotes, purchase policies, activate or deactivate your plans, include additional advantages to your existing or needed plans, and a lot more services that are tailored to make certain you have a bespoke and seamless experience.
                        </div>
                   </div>
                </div>
            </div>
            
            {/* <Insurance category={category} /> */}

            {/* <WhyChoose reference={aboutMe} /> */}

            <div className="container">
                <div className="best">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="save">
                                <h1>Save <span className="up">Upto 90%</span>  with Best </h1>
                                <h2>Insurer of Choice for Customers <br /> in UAE </h2>
                                <p>Beemabazaar is a trusted insurer based in UAE. Our top priority has always been to safeguard your home, your family or your prized possession from the most unforeseen events. Our diverse range of policies comprises car insurance, health insurance, child savings plan, term life insurance among others. </p>
                                <Link to="/generalinsurance?category=General Insurance">Get Your Quote</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="family text-center">
                                <img src="images/save_03.png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OurPartner partner={ourPartner} reference={partnerRef} />

            {/* <div className="container">
                <div className="all">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="choose">
                                <h1>Choose and Apply for<br />
                                <   span className="up">personal </span>credit products<br />
                                    on Beemabazaar Insurance
                                </h1>
                                <p>Our team at Beemabazaar believes we offer a match<br />
                                less customer experience and we trust our members<br />
                                appreciate it too! The reasons below are just a few:.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="wide">
                                        <img src="images/00000_03.png" />
                                        <h5>Wide Choice</h5>
                                        <p>Customers have a wide array of<br />
                                        options to choose from</p>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="policies">
                                        <img src="images/1111_03.png" />
                                        <h5>Policies can be<br />
                                            chosen easily
                                        </h5>
                                        <p>Online car insurance policy issu<br />
                                        ance in just a matter of minutes.<br />
                                        Easy, quick and digital.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="wide">
                                        <img src="images/333_03.png" />
                                        <h5>Best prices</h5>
                                        <p>We provide competitive prices<br />
                                        and comprehensive products<br />
                                        that are fit for your particular<br />
                                        needs - delivered from trusted<br />
                                        insurers at an affordable price</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="policies">
                                        <img src="images/333_06.png" />
                                        <h5>Quick response<br />
                                        chosen easily</h5>
                                        <p>We have Responsive, trusted<br />
                                        and committed personnel to<br />
                                        provide advice and help when<br />
                                        our customer wants it the most,<br />
                                        in the quickest possible way. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container mb-5">
                <div className="row">
                    <div className="col-md-6">
                        <div className="choose">
                            <h1>Choose and Apply for<br />
                            <span className="up">personal </span>credit products<br />
                                on Beemabazaar Insurance
                            </h1>
                            <p>Our team at Beemabazaar believes we offer a match
                            less customer experience and we trust our members
                            appreciate it too! The reasons below are just a few:.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 mt-4">
                                <div className="choose-grids">
                                    <img src="images/00000_03.png" />
                                    <h5>Wide Choice</h5>
                                    <p>Customers have a wide <br /> 
                                    array of options to choose from</p>

                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="choose-grids">
                                    <img src="images/1111_03.png" />
                                    <h5>Policies can be<br />
                                        chosen easily
                                    </h5>
                                    <p>Online car insurance policy issuance in just a matter of minutes.
                                    Easy, quick and digital.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-4">
                                <div className="choose-grids">
                                    <img src="images/333_03.png" />
                                    <h5>Best prices</h5>
                                    <p>We provide competitive prices
                                    and comprehensive products
                                    that are fit for your particular
                                    needs - delivered from trusted
                                    insurers at an affordable price</p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="choose-grids">
                                    <img src="images/333_06.png" />
                                    <h5>Quick response<br />
                                    chosen easily</h5>
                                    <p>We have Responsive, trusted
                                    and committed personnel to
                                    provide advice and help when
                                    our customer wants it the most,
                                    in the quickest possible way. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <section className="request">
                <div className="container">
                <h3>Request a Free Consultation!</h3>
                <p>We provide a free consultation to aid our clients to know every policy option. </p>
                <Link to="/generalinsurance?category=General Insurance">Get Your Quote</Link>
                </div>
            </section>
            {testimonial.length > 0? 
                <Testimonial testimonial={testimonial} reference={reviewRef} />
            : ''}
            <Footer />

            <ScrollToTopManual />

        </div>
            
    );
}

export default Home;