import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IMAGE_URL, PUBLIC_URL } from '../../../CommonString';
import SpinnerComponent from '../../common/SpinnerComponent';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';
import Select from 'react-select';  
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated(); 

const myFunction = () => {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Show more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Show more"; 
      moreText.style.display = "inline";
    }
}

// let carList = [
//   {
//     value: 'toyota',
//     text: 'Toyota',
//   },
//   {
//     value: 'honda',
//     text: 'Honda',
//   },
//   {
//     value: 'mitsubshi',
//     text: 'Mitsubshi',
//   },
//   {
//     value: 'hyundai',
//     text: 'Hyundai',
//   },
//   {
//     value: 'ford',
//     text: 'Ford',
//   },
//   {
//     value: 'mercedes',
//     text: 'Mercedes',
//   },
//   {
//     value: 'audi',
//     text: 'Audi',
//   },
//   {
//     value: 'bmw',
//     text: 'BMW',
//   },
//   {
//     value: 'volkswagen',
//     text: 'Volkswagen',
//   },
// ]


const CarMake = (props) => {

    const [make, setMake] = useState('');
    const [makeId, setMakeId] = useState(props.makeValue ? props.makeValue : '');
    const [errorMake, setErrorMake] = useState(false);
    const [carList, setCarList] = useState([]);
    const [more, setMore] = useState(true);
    const [loadeing, setLoading] = useState(false);
    const [carItems, setCarItems] = useState([]);

    const pageUpdate = () => {
      if(makeId === ''){
        setErrorMake(true);
      }
      else{
        setErrorMake(false);
        props.pageUpdate();
      }
    }
    const pageDown = () => {
        props.pageDown();
    }

    const makeSelect = (make, id) => {
      setMake(make);
      setMakeId(id);
      props.make(make, id);
      // pageUpdate();
    }

    const makeChange = (e) => {

      // let index = e.nativeEvent.target.selectedIndex;
      // let makeText = e.nativeEvent.target[index].text;

      let value = e.value;
      let makeText = e.label;

      setMake(makeText);
      setMakeId(value);
      props.make(makeText, value);
    }

    useEffect(() => {
      
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

      setLoading(true);

      axios({

        url: `${PUBLIC_URL}/make/list`,
        method: 'POST',
        
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'success'){
          setCarItems((response.data.data ? response.data.data.map(data => ({ label: data.name, value: data.id })) : []));
          setCarList(response.data.data);
        }
      }).catch((error) => {
        setLoading(false);
      });

      

    }, [makeId, errorMake]);

    
    // const cars = () => {  
    //   carItems = (carList.map(data => ({ label: data.name, value: data.id })));
      
    // }

    const onNothingClick = (e) => {

      e.preventDefault();
  }

   
    return (
      <>
        <SpinnerComponent open={loadeing} />
        <div className="form-container animated active flipInX">
          
            {/* {makeId ? <div className="view animated" style={{top: '12px', backgroundColor: '#FFF'}}>
              <a href onClick={onNothingClick} style={{padding: '38px 17px'}}>
                {carList.map((car, index) => {
                  if(car.id === makeId)
                    return <img key={index} src={IMAGE_URL + '/' + car.image} />
                })}
                </a>
            </div> : '' } */}
          <h2 className="text-center form-title">Car Make</h2>
          {/* <Autocomplete
            onChange={(e, carItems) => makeChange(e, carItems.value)}
            id="carMake_select"
            options={carItems}
            getOptionLabel={(option) => option.label}
            filterOptions={filterOptions}
            sx={{ width: '100%', padding: 'none', paddingRight: '0px' }}
            renderInput={(params) => <TextField {...params} label="Car Make" />}
          /> */}
          <Select onChange={makeChange} filterProp="label" options={carItems} components={animatedComponents} />
            {/* <select className="custom-select padd" onChange={makeChange} id="gender2">
              <option>Car Make</option>
              {carList.map((car, index) => {
                if(car.id == makeId){
                  return <option key={index} selected value={car.id}>{car.name}</option>
                }
                else{
                  return <option key={index} value={car.id}>{car.name}</option>
                }
              })}
            </select>  */}

            {/* {makeId ? <div className="view animated" style={{top: '12px', backgroundColor: '#FFF'}}>
              <a href onClick={onNothingClick} style={{padding: '38px 17px'}}>
                {carList.map((car, index) => {
                  if(car.id === makeId)
                    return <img key={index} src={car.image} />
                })}
                </a>
            </div> : '' } */}
            <br />
            {makeId ?  <div className="car-make-selected-div"> 
            
              <div className="car-make-selected-div-selected">
              <span className="currently-selected-year">Currently Selected:</span>
              {carList.map((car, index) => {
                if(car.id == makeId)
                return  <span key={index} className="selected-car-brand-img">{car.name}</span>
              })}
              </div>
            </div>
            : '' }


            <div className="select-year">
            {more ? <div className="year-wrapper-logos">
                {carList && carList.map((car, index) => {
                  if(index < 9)
                    return (<div key={index} className="year-box-logos image-text" onClick={() => makeSelect(car.name, car.id)}>
                        <a className="year-box-href" href onClick={onNothingClick}>
                          {/* <img src={IMAGE_URL + '/' + car.image} className="img-fluid logo-images" /> */}
                        </a>
                      <div style={{"font-weight": "700"}}>{car.name}</div>
                    </div>);
                })}
              </div>
              :
              <div className="year-wrapper-logos" style={{overflowY: 'auto', maxHeight: '40vh'}}>
                {carList && carList.map((car, index) => {
                    return (<div key={index} className="year-box-logos image-text" onClick={() => makeSelect(car.name, car.id)}>
                        <a className="year-box-href" href onClick={onNothingClick}>
                          {/* <img src={IMAGE_URL + '/' + car.image} className="img-fluid logo-images" /> */}
                        </a>
                      <div style={{"font-weight": "700"}}>{car.name}</div>
                    </div>);
                })}
              </div>
            }
            {errorMake ? <div style={{color: 'red'}}>Make is required</div> : ''}
            <button onClick={() => setMore(!more)} id="myBtn">{more ? 'Show more' : 'Show less' }</button>
          </div>
          <div className="form-group text-center mar-b-0">
            <input type="button" onClick={pageDown} value="BACK" className="btn btn-default back mr-2" />      
            <input type="button" onClick={pageUpdate} value="NEXT" className="btn btn-primary next" />        
          </div>
        </div>
      </>
    );
};

export default CarMake;