import React from 'react';

const CommunicationChannel = () => {
    return (
        <div>
            <div className="container">
                <div className="complaint-hedear">
                    <h2>Communication Channel And Escalation Point</h2>
                    <hr />
                    <p>↠ In case of denials –Late submission , standard exclusion as per policy terms &amp; conditions<br />
                        ↠ Huge deductions –copays , UCR charges ,non eligible items
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="text-tabil-wrapper">
                    <div className="col-md-12">
                        <div className="blog-post-title">
                            <table width="100%" border="1">
                                <tr>
                                    <td style={{padding: '20px'}} valign="top"><strong>Service</strong></td><td style={{padding: '20px'}}><strong>Communication Mode/Requirements</strong></td>
                                </tr>
                                <tr>
                                    <td style={{padding: '20px'}} valign="top">Pre - Approvals &amp; Medical Queries (Preauthorization &amp; Reimbursement )</td>
                                    <td style={{padding: '20px'}}>
                                        Dr Mohammed Rashid<br />
                                        <strong>Claim Officer – Medical Department</strong><br />
                                        <strong>Med3@omegainsurance.ae</strong><br />
                                        04 302 44 46<br /><br />
                                        <strong>Escalation Communication Channel</strong><br />
                                        <strong>Dr Neeba</strong><br /> 
                                        <strong>Claims Manager – Medical Department</strong><br />
                                        <strong>med10@omegainsurance.ae</strong><br />
                                        04 302 44 93<br /><br />

                                        <strong>Management Escalation</strong><br />
                                        <strong>Mr. Shabnas</strong><br />
                                        <strong>AGM – Medical Department</strong><br />
                                        <strong>shabnas@omegainsurance.ae</strong><br />
                                        043024442<br />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding: '20px'}} valign="top"><strong>Reimbursement claim</strong> </td><td style={{padding: '20px'}} valign="top">
                                        <strong>Ms. Leslie Shane Bugayong</strong><br /> 
                                        <strong>Claim Officer – Medical Department</strong><br />
                                        <strong>Med4@omegainsurance.ae</strong><br />
                                        04 302 45 31

                                        <strong>Escalation Communication Channel</strong><br />
                                        <strong>Dr Neeba </strong><br />
                                        <strong>Claims Manager – Medical Department</strong><br />
                                        <strong>med10@omegainsurance.ae</strong><br />
                                        04 302 44 93
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunicationChannel;