import React, { useState } from 'react';

const ScrollToTopManual = () => {

    const [topButton, setTopButton] = useState(false);


    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};

    const scrollFunction = () => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setTopButton(true);
        } else {
            setTopButton(false);
        }
    }

    const scrollToTop = () => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    const onNothingClick = (e) => {

        e.preventDefault();
    }

    return (
        <>
            {topButton == true ? 
                <a href onClick={onNothingClick} className="scrollUpButton" id="myBtn" onClick={scrollToTop}><i className="fa fa-chevron-up" aria-hidden="true"></i></a> 
            : '' }
        </>
    );
};

export default ScrollToTopManual;