import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { IMAGE_URL } from '../../../CommonString';

const Testimonial = (props) => {

    const testimonial = props.testimonial;

    const onNothingClick = (e) => {

        e.preventDefault();
    }
    
    let testimonialItems = testimonial.length > 0 ? testimonial.map((testimonial, index) => {
                                   
        return(
            <div className="card" key={index} style={{height: '380px'}}>
                <ul>
                    <li><a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a></li>
                    <li><a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a></li>
                    <li><a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a></li>
                    <li><a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a></li>
                    <li><a href onClick={onNothingClick}><i className="fa fa-star-half-o" aria-hidden="true"></i></a></li>
                </ul>
                <p>{testimonial.description}</p>
                <div className="user-testi-box">
                    <div className="user-tseti-img"><img src={`${IMAGE_URL}/` + testimonial.image} className="img-fluid" /></div>
                    <div className="user-profile-info">
                        <h5>{testimonial.name}</h5>
                        <span>{testimonial.designation}</span>
                    </div>
                </div>
            </div>
        )
    }) : '';

    return (
        <section className="thousensd" ref={props.reference}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="review-head">Reviews</h1>
                        <hr />
                        <div className="our">
                            <h3>Take a look at a couple of <span className="up">Testimonials</span> by our customers:</h3>
                            <p>Following are some testimonials written by Beemabazaar customers<br />
                            who have availed of our finest services.</p>
                            <h6>Thousands of unbiased reviews. Trusted by 10 Thousand+<br />
                            customers.</h6>
                            <ul className="overallrating mt20">
                            <li> <a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href onClick={onNothingClick}><i className="fa fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href onClick={onNothingClick}><i className="fa fa-star-half-o" aria-hidden="true"></i></a> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="banner-testi testimonial-section">
                            <OwlCarousel className="slider owl-carousel" loop={true} margin={10} nav={true} autoplay={false} autoplayTimeout={2000} items={1} navText={['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']}>
                                {testimonialItems}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;